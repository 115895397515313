.container {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	padding: 10px;
	cursor: pointer;
	background-color: #4d5760;
	border-top-left-radius: var(--sterry-tabs-border-radius);
	border-top-right-radius: var(--sterry-tabs-border-radius);
}

.active {
	background-color: #2d363e;
}
.container {
	display: flex;
	background-color: transparent;
	border: 0px;
	cursor: pointer;

	& > svg {
		margin: auto;
		fill: #fff;
	}
}

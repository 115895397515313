.container {
	&__manage-plan {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		&__actions {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			width: 100%;
			gap: 20px;
			min-width: 25%;
		}

		&__description {
			font: normal normal 300 14px/29px Poppins;
			width: 100%;

			& h3 {
				font-weight: 600;
				display: flex;
				align-items: center;
				gap: 5px;
			}

			&__apple {
				display: flex;
				align-items: center;
				gap: 10px;

				svg {
					width: 26px;
				}
			}
		}
	}

	&__pro-orgs {
		display: flex;
		flex-direction: column;

		.orgInfoContainer {
			margin-top: 10px;
		}
	}
}

.button {
	white-space: nowrap;
	min-width: 125px;
	width: fit-content;
}

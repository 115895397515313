.container {
	display: flex;
	margin: 10px;
	position: relative;
	z-index: 1;
	user-select: none;

	& > ul {
		color: #ffffff;
		display: none;
		position: absolute;
		top: 0px;
		right: 2px;
		z-index: 10;
		background-color: #272727;
		border-radius: 4px;
		border: 1px solid #333333;
		max-width: 350px;
		min-width: 100px;
		width: auto;
		white-space: nowrap;
		font-size: 14px;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);

		.navigation_items {
			display: none;
			& a {
				width: 100%;
			}
			@media screen and (max-width: 800px) {
				display: flex;
			}
		}

		& > li {
			padding: 10px;
			cursor: pointer;
			display: flex;
			align-items: center;

			& a {
				text-decoration: none;
				color: #cccccc;
			}

			& > svg {
				height: 40px;
				width: 40px;
				margin-right: 10px;
			}

			&:last-child {
				border-bottom-left-radius: inherit;
				border-bottom-right-radius: inherit;
			}
			&:hover {
				background-color: #303034;
			}
			&:first-child {
				border-top-left-radius: inherit;
				border-top-right-radius: inherit;
				&:hover {
					background-color: transparent;
					cursor: unset;
				}
			}
		}
	}

	&--open {
		& > ul {
			display: block;
		}

		.background {
			display: block;
		}
	}
}

.profile {
	display: flex;
	align-items: center;
	width: auto;

	&__icon {
		width: 48px;
		height: 48px;

		&:hover {
			cursor: pointer;
			opacity: 0.75;
		}
	}

	& img {
		border-radius: 50%;
		width: 100%;
		max-height: 48px;
		max-width: 48px;
	}

	&__user {
		display: flex;
		flex-direction: column;
		margin-left: 5px;

		& > * {
			display: block;
			width: 200px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.background {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9;
	background-color: rgba(0, 0, 0, 0.2);
}

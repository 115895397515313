.container {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.featureItem {
		margin-top: 10px;
		svg {
			margin-right: 10px;
		}
	}

	.buttons {
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
		gap: 12px;
	}
}

.container {
	height: calc(100vh - var(--sterry-header-height));
	overflow-y: auto;
	width: 100%;

	&--disable-overflow {
		overflow-y: unset;
	}

	&--enable-gutter {
		scrollbar-gutter: stable;
	}
}

.container--max {
	height: 100vh;
	overflow-y: auto;
	width: 100%;

	&--disable-overflow {
		overflow-y: unset;
	}

	&--enable-gutter {
		scrollbar-gutter: stable;
	}
}

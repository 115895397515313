.container {
	color: #858b8f;
	font-size: 18px;
	text-decoration: none;
	display: block;
	overflow-x: hidden;

	// Set up the animated bar
	.container__bar {
		transition: opacity 400ms, transform 400ms;
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
		border-bottom: 3px solid #cccccc;
	}

	// On Hover of the parent then animate the child
	&:focus,
	&--active,
	&:hover {
		color: #ffffff;
		.container__bar {
			opacity: 1;
			transform: translate3d(0%, 0, 0);
		}
	}
}

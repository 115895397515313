.modal {
	& svg {
		fill: #414141;
	}
	& > * {
		background-color: #fff;
	}

	& > div > div:nth-child(2) {
		color: #414141;
		font-weight: 400;
	}
}

.container {
	margin-top: 16px;
	display: flex;
	gap: 14px;
	width: 100%;
	flex-wrap: wrap;

	& > div {
		width: fit-content;
		padding: 3px 6px 0px 6px;
		border-radius: 4px;
	}
}

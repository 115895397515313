.container {
	width: 100%;

	& > * {
		border-radius: 4px;
		background-color: #171717;
		border: 1px solid #575757 !important;
	}
}

.dropdown-component {
	& div {
		&:nth-child(1) {
			width: 100%;
			display: flex;
			align-items: center;
		}

		&:nth-child(2) {
			overflow-y: scroll;
			height: 150px;
			margin-top: 50px;
			text-align: left;
		}
	}
}

.country-name {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.country-selector {
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	padding: 10px;
	text-align: right;

	&:hover {
		cursor: pointer;
	}

	& p {
		margin: 0;
	}
}

.dropdown-icon {
	fill: #fff;
}

.tabsContainer {
	margin-top: 20px;
	width: 100%;
	display: flex;
	justify-content: center;

	& > div {
		min-width: 40%;
	}

	min-width: 400px;
	margin-left: 40px;
}

.discrepancies-container {
	margin-right: 40px;
	margin-top: 10px;

	& > div {
		display: flex;
		gap: 6px;
		justify-content: flex-start;
		font-size: 12px;
		align-items: center;
	}
}

.horizontal-container {
	display: flex;
	justify-content: space-between;
}

.button {
	width: 100px;
}

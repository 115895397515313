.lynxCard {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #ffffff;
	border-radius: 10px;
	border-top: 8px solid #000000;
	position: relative;
	color: #000000;
	max-width: 480px;

	.header {
		& > div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		& h3 {
			margin-top: 15px;
			font-size: 28px;
			font-weight: 300;
		}
	}

	.headerIconRow {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
		position: absolute;
		top: -32px;
		width: 100%;
	}

	.headerIcon {
		width: 64px;
		height: 64px;
		background-color: #ffffff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

		& > svg {
			width: 46px;
			height: 46px;
		}
	}

	.cardContent {
		padding: 20px;
		display: flex;
		flex-direction: column;

		& > div {
			margin-bottom: 20px;
		}

		& > div:last-of-type {
			margin-bottom: 0;
			height: 220px;
		}

		h2 {
			text-align: center;
		}

		.cardContent {
			flex: 1;
			.image {
				min-height: 217px;
				width: 100%;
				height: 100%;
				object-fit: contain;
				border-radius: 10px;
			}
		}

		.bulletPointsContainer {
			margin-top: 15px;
		}
	}

	img {
		display: flex;
		flex: 1;
		width: 100%;
	}

	h2 {
		font-size: 1.3rem;
		font-weight: 700;
		margin: 0;
		text-align: center;
		margin-bottom: 30px;
	}

	.buttonRow {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;
		width: 100%;
		column-gap: 10px;
		flex-direction: column;
		justify-content: flex-start;
		gap: 4px;

		& button {
			height: 48px;
			white-space: nowrap;
		}
	}
}

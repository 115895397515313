.container {
	display: flex;
	width: 100%;

	.autocomplete {
		flex: 1;
	}

	.btnContainer {
		width: 170px;
		margin-left: 15px;
		display: flex;
		align-items: flex-end;
		justify-content: right;
	}
}

.newCustomerContainer {
	margin-top: 25px;

	& > * {
		margin-top: 10px;
	}

	.controlBtnsContainer {
		justify-content: right;
		display: flex;
		margin-top: 25px;

		& > :first-child {
			margin-right: 10px;
		}
	}
}

.customerItem {
	display: flex;
	align-items: center;

	& > :first-child {
		margin-left: 10px;
	}
}

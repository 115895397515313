.container {
	height: 64px;
	width: 234px;
	display: flex;
	color: #ffffff;
	background-color: #000000;
	border-radius: 4px;
	border: 1px solid #ffffff;
	user-select: none;
	cursor: pointer;
	text-decoration: none;

	@media (max-width: 500px) {
		width: 100%;
		align-content: center;
		justify-content: center;
	}

	&__body {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-items: center;
		justify-self: center;
	}

	&__label {
		grid-column: 2;
		grid-row: 1;
		font-size: 12px;
		line-height: 1;
	}
	&__text {
		grid-column: 2;
		grid-row: 2;
		font-size: 23px;
		font-weight: 400;
		line-height: 1;
	}
	&__icon {
		grid-column: 1;
		grid-row: 1/3;
		width: 44px;
		fill: #ffffff;
		display: flex;
		justify-content: center;

		& > svg {
			width: 27px;
		}
	}

	&:hover {
		filter: brightness(1.04);
	}
}

.container {
	display: flex;
	align-items: flex-start;

	&__bullet svg {
		width: 24px;
		height: 24px;
	}

	&__text {
		margin-left: 7px;
	}
}

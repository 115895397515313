.headerContainer {
	font-weight: 500;
	color: #fff;
	background-color: #373737;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	display: grid;
}

.cellPadding {
	composes: cellPadding from './Table.module.css';
}

.headerCell {
	cursor: pointer;
	display: flex;
	align-items: center;
	font-weight: 500;
}

.headerCell:hover {
	opacity: 0.75;
}

.sortArrow {
	margin-left: 7px;
	transition: transform 0.2s;
	fill: #636363;
	width: 15px;
	height: 15px;
}

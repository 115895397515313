.currencies {
	margin-bottom: 10px;
	grid-column: 3;
}

.containerPricing {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;

	.toggleRow {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		gap: 10px;
	}

	.pageTitleRow {
		margin-top: 50px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		width: 100%;
		justify-items: center;
		padding: 0px 20px;
	}

	.pageTitle {
		font-size: 2.5rem;
		line-height: 1.2;
		text-align: center;
		font-weight: 300;
		margin: 0;
		margin-bottom: 20px;
		grid-column: 2;
	}

	.svgFillGrey {
		fill: #9d9d9d;
	}

	.svgFillPro {
		fill: #5632d4;
	}

	.svgFillBlue {
		fill: #0063f7;
	}

	.cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: stretch;
		justify-items: center;
		gap: 2%;
		color: #000;
		margin-top: 50px;

		@media (max-width: 480px) {
			flex-direction: column;
			align-items: center;
			padding: 0 20px;
		}

		& > div {
			width: 32%;
			min-width: 370px;
			max-width: 470px;

			@media (max-width: 1224px) {
				margin-bottom: 50px;
			}

			@media (max-width: 480px) {
				width: 100%;
				min-width: 100%;
				max-width: 100%;
			}
		}

		.ctaButton {
			display: block;
			font-weight: 700;
			min-width: 70%;
			width: 70%;
		}

		.cardHeader {
			display: flex;
			flex-direction: column;

			.cardTitle {
				display: flex;
				flex-direction: row;
				justify-content: center;
				margin-bottom: 44px;

				svg {
					height: 30px;
				}
			}

			.cardDesc {
				display: flex;
				flex-direction: column;
				text-align: center;
				font-size: 40px;
				font-weight: 200;
				line-height: 30px;
				height: 55px;

				span {
					font-size: 12px;
					text-transform: none;
				}
			}
		}

		.cardBody {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			gap: 15px;

			p {
				margin: 0;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: flex;
					margin-bottom: 10px;
					gap: 10px;

					svg {
						margin-top: 3px;
						width: 15px;
						height: 15px;
					}
				}

				li:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}

.pageLightContent {
	margin-top: 50px;
	background-color: #f6f6f6;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	color: #000;

	.pageTitle {
		padding: 50px 20px;
		font-size: 2.5rem;
		line-height: 1.2;
		text-align: center;
		font-weight: 300;
		margin: 0;

		@media (max-width: 480px) {
			padding: 20px 20px;
		}
	}

	.pricingTable {
		width: 70%;
		margin-bottom: 100px;

		@media (max-width: 480px) {
			width: 100%;
		}

		tbody svg {
			width: auto;
			height: 25px;
		}

		td {
			&:first-child {
				& > svg {
					cursor: pointer;
					vertical-align: bottom;
					margin-left: 5px;
				}
			}
			padding: 15px;
			border-right: 1px solid #eaeaea;
			border-bottom: 1px solid #eaeaea;

			&:last-of-type {
				border-right: none;
			}
		}

		.tdIcon {
			text-align: center;
			border-right: none;
			padding-right: 0;

			svg {
				width: 30px;
				height: 30px;
			}
		}

		.tdProduct {
			display: flex;
			gap: 10px;
			align-items: center;
			border-right: none;
			padding-right: 0;
			font-weight: 400;
			color: #414141;

			svg {
				width: 30px;
				height: 30px;
			}
		}

		.svgFillGrey {
			fill: #9d9d9d;
		}

		.svgFillPro {
			fill: #5632d4;
		}

		.svgFillBlue {
			fill: #0063f7;
		}

		tr:last-of-type td {
			border-bottom: none;
		}

		.tdBold {
			font-weight: 700;
		}

		.tdCenter {
			width: 20%;
			text-align: center;
		}

		.tdBoldCenter {
			width: 20%;
			text-align: center;
			font-weight: 700;
		}
	}
}

.icon-layout {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	flex-wrap: wrap;
}

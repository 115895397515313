.container {
	margin-top: 40px;
	margin-bottom: 40px;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	background: var(--sterry-modal-background-color);
	border-radius: var(--sterry-modal-border-radius);
	padding: var(--sterry-modal-padding) 0 20px 0;
	display: flex;
	flex-direction: column;
	border-style: solid;
	border-width: 1px;
	border-color: var(--sterry-color-grey);
	box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.textClearAll {
	color: #4099ff;
	font-weight: 600;
	margin-right: var(--sterry-modal-padding);
	cursor: pointer;
}

.textClearAll:hover {
	opacity: 0.75;
}

.textHeader {
	padding-left: var(--sterry-modal-padding);
	font-size: 18px;
	font-weight: 600;
}

.showMoreContainer {
	display: flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	color: #4099ff;
	padding-top: 20px;
	padding-bottom: 10px;
	font-weight: 600;
}

.showMoreContainer:hover {
	opacity: 0.75;
}

.container {
	position: relative;

	.orgItem {
		display: flex;
		align-items: center;

		& > :first-child {
			margin-left: 10px;
			width: 130px;
		}
	}

	.listContainer {
		background-color: var(--sterry-modal-background-color);
		position: absolute;

		.singleItem {
			padding: 1rem;
			border-bottom: 1px solid grey;
			cursor: pointer;

			&:hover {
				opacity: 0.75;
			}
		}
	}
}

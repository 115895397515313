.container {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.buttons {
	justify-content: flex-end;
	display: flex;
	gap: 16px;
	width: 100%;
}

.rotate-anim {
	animation: 1s ease-in-out 0s 1 rotateKeyframes;
	transition-delay: 250ms;
}

@keyframes rotateKeyframes {
	0% {
		rotate: 0turn;
	}
	100% {
		rotate: 5turn;
	}
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	bottom: 10px;
	right: 10px;
	background-color: #303034;
	align-items: flex-start;
	width: 330px;
	padding: 15px;
	font-size: 16px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
	border-radius: var(--sterry-modal-border-radius);
	z-index: 10;
	border: 1px solid #707070;
	border-top: 8px solid #0063f7;

	.question {
		text-align: center;
	}

	.tips {
		font-size: 11px;

		a {
			font-weight: bold;
			color: #fff;
		}
	}

	.buttons-section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 10px;
		width: 100%;
		margin-top: 20px;

		button {
			display: flex;
			flex: 1;
			width: 100%;
		}
	}

	& > div {
		margin-bottom: 20px;

		& > label {
			display: flex;
			color: #cecece;
			font-size: 14px;
			font-weight: 500;
			margin-bottom: 8px;
		}
	}

	& > .star-rating-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	.form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;
		justify-content: space-between;
	}

	.textfield {
		background-color: #646a73;
		margin-top: 20px;
	}

	.textfield > input {
		color: white;
	}

	.textfield > input:focus {
		border: 1px solid white;
	}
}

.container svg {
	width: 24px;
}

.container h3 {
	font-size: 18px;
	margin-bottom: 15px;
}

.header {
	display: flex;
	justify-content: space-between;
}

.close-button {
	fill: #fff;
}

.close-button:hover {
	opacity: 0.75;
	cursor: pointer;
}

.button {
	width: 35%;
}

.button-container {
	margin-top: 16px;
	display: flex;
	justify-content: flex-end;
}

.feedbackSentContainer {
	display: flex;
	justify-content: space-between;
}

.dropdown {
	height: 100% !important;
	display: flex;
	align-items: center;
	z-index: 2;
	width: 30px;

	& > div > svg {
		fill: #fff;
	}
}

.popup {
	border-radius: 4px;
	border: 1px solid #ececec;
	min-width: 140px;
	width: fit-content;
	background-color: #272727;
	height: auto;
	box-shadow: 0px 3px 6px #17171729;
	font-size: 14px;
	color: #fff;

	& > div {
		height: auto;
	}

	& > ul {
		& > li {
			padding: 9px 10px;
			color: #f7f7f7;
			display: flex;
			align-items: center;
			column-gap: 10px;
			justify-content: center;
			white-space: nowrap;
			text-align: center;

			& svg {
				width: 24px;
				height: 24px;
			}

			&:hover {
				background-color: #303034;
				color: #fff;
				cursor: pointer;
			}

			&:first-child {
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}

			&:last-child {
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
}

.container {
	position: fixed;
	right: 10px;
	z-index: 2;

	border-radius: 6px;
	min-width: 350px;
	background: #272727;
	border: 1px solid #707070;

	.header {
		border-radius: 6px;
		padding: 15px 2px 15px 10px;
		border-bottom: 1px solid #707070;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.iconContainer {
			display: flex;
			align-items: center;
			margin-right: 3px;

			.minimiseIcon {
				margin-right: 6px;
			}

			svg {
				transition: transform 0.2s;
				fill: #fff;
				cursor: pointer;
				width: 25px;
				height: 25px;

				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	.body {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		background: #474747;
		max-height: 500px;
		overflow-y: auto;
		transition: max-height 0.3s;

		.item {
			display: flex;
			padding: 6px 3px 10px 10px;
			align-items: center;
			flex-direction: row;

			.fileIcon {
				width: 30px;
				height: 30px;
			}
			.loadingSpinner {
				margin-right: 3px;
				margin-left: 5px;
			}

			.nameContainer {
				flex: 1;
				margin-left: 10px;

				.name {
					font-size: 14px;
					font-weight: 500;
					color: #fff;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 250px;
				}
				.status {
					font-size: 12px;
					color: #fff;
					opacity: 0.8;
				}
			}

			.completedIcon,
			.closeIcon,
			.failedIcon {
				width: 30px;
				height: 30px;
				margin-left: 0 6px;
				cursor: pointer;
			}

			.closeIcon {
				fill: #fff;

				&:hover {
					opacity: 0.7;
				}
			}
			.completedIcon {
				fill: #6ed4b8;
			}
			.failedIcon {
				fill: #ea6170;
			}
		}
	}
}

.container {
    border-radius: var(--sterry-textfield-border-radius);
    background-color: var(--sterry-textfield-background-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.container > input {
    border-radius: var(--sterry-textfield-border-radius);
    background-color: inherit;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    color: var(--sterry-textfield-color);
    padding-left: var(--sterry-textfield-padding-left);
    width: inherit;
}

.container input:disabled {
    cursor: not-allowed;
} 

.container > div {
    height: inherit;
}

/* Placeholder */

.container > input::placeholder {
    color: var(--sterry-textfield-placeholder-color);
    padding: var(--sterry-textfield-placeholder-padding-up) var(--sterry-textfield-placeholder-padding-right) var(--sterry-textfield-placeholder-padding-down) var(--sterry-textfield-placeholder-padding-left);
}

.container > input:focus {
	border: 1px solid white;
}

.placeholder-center > input::placeholder {
    text-align: center;
    padding-right: var(--sterry-textfield-padding-left)
}

.placeholder-end > input::placeholder {
    text-align: end;
    padding-right: var(--sterry-textfield-padding-left);
}


/* Adornment */

/* Set defaults for when an svg is present as an adornment */
.container > svg {
    margin: var(--sterry-textfield-svg-margin) 0px var(--sterry-textfield-svg-margin) var(--sterry-textfield-svg-margin);
    fill: var(--sterry-textfield-placeholder-color);
}

/* Default input box would be on right with an adornment, so remove left side border-radius */
.adornment > input  {
   border-top-left-radius: 0px; 
   border-bottom-left-radius: 0px;
} 

/* Also set the adornment container to have no border radius on the right side */
.adornment > div {
    border-top-right-radius: 0px; 
    border-bottom-right-radius: 0px;
}

/* Row reverse the elements when adornment justification is on right */
.adornment-right {
    flex-direction: row-reverse;
}

.adornment-right > svg {
    margin: var(--sterry-textfield-svg-margin) var(--sterry-textfield-svg-margin) var(--sterry-textfield-svg-margin) 0px; 
}

/* Reverses the border radii for right side adornments */
.adornment-right > div {
    border-radius: 0px var(--sterry-textfield-border-radius) var(--sterry-textfield-border-radius) 0px;
}

/* Reverses the border radii of the input element for right side adornments */
.adornment-right > input {
    border-radius: var(--sterry-textfield-border-radius) 0px 0px var(--sterry-textfield-border-radius);
}

.grey {
    background-color: var(--sterry-color-grey);
}

.grey input {
    color: white;
}
/* sizes */
.md.container {
    input {
        padding: 14.5px 10px;
        font-size: 16px;
    }
}

.sm.container {
    input {
        padding: 9px;
        font-size: 16px;
    }
}
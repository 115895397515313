.statusContainer {
	display: flex;
	justify-content: center;
	width: 100%;

	.badgeStatus {
		font-weight: 600;
		white-space: nowrap;
	}
}

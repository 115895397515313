.container {
	/* min-width: 600px; */
	/* min-height: 500px; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.pdf-container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pdf-container > svg {
	font-size: 50px;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;

	& > div {
		font-size: 18px;
		margin-bottom: 10px;
	}
}

.container {
	background-color: transparent;
	color: #494f5d;
	border-bottom-left-radius: 8px;
	flex-direction: column;
	display: flex;
	align-items: center;
	height: 100%;
}

.side-left,
.side-right {
	width: 50%;
}

.side-right {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.title {
	font-size: 25px;
	color: #4f5864;
	margin-top: 9px;
	text-align: center;
}

.title img {
	cursor: pointer;
}

.justify-between > div:first-child {
	justify-content: space-between;
	display: flex;
	width: 100%;
	align-items: center;
	user-select: none;
}

@media (max-width: 900px) {
	.side-left {
		display: none;
	}

	.side-right {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		width: 100%;
	}
}

.container {
	display: flex;
	align-items: center;

	.pictureContainer {
		overflow: hidden;
		width: 64px;
		height: 64px;
		margin-right: 20px;
	}

	.uploadedImg {
		width: 100%;
		height: 100%;
	}

	.pickerContainer {
		display: flex;
		flex-direction: column;
	}
}

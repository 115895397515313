.container {
	width: 100%;

	& > * {
		&:nth-child(even) {
			background-color: #fcfcfc;
		}
		&:nth-child(odd) {
			background-color: #f7f7f7;
		}

		border-bottom: solid #e7e7e7;

		&:last-child {
			border-bottom: unset;
		}
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 15px;
}

.dropdown {
	height: 100% !important;
	display: flex;
	align-items: center;
}

.popup {
	border-radius: 4px;
	border: 1px solid #ececec;
	min-width: 200px;
	width: fit-content;
	background-color: #fff;
	height: auto;
	box-shadow: 0px 3px 6px #17171729;
	font-size: 14px;
	color: #272727;

	& > div {
		height: auto;
	}

	& > ul {
		& > li {
			padding: 9px 10px;
			background-color: #fff;
			color: #687b8c;
			display: flex;
			align-items: center;
			column-gap: 10px;

			& svg {
				width: 24px;
				height: 24px;
			}

			&:hover {
				background-color: #0063f7;
				color: #fff;
				cursor: pointer;
			}

			&:first-child {
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}

			&:last-child {
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
}

.container {
	width: 100%;
	background-color: #374151;
	border-radius: 9999px;
	height: 12px;

	.bar {
		background-color: var(--sterry-color-positive);
		height: 100%;
		border-radius: 9999px;

		font-size: 9px;
		font-weight: 600;
		text-align: center;
		padding: 0.5px 0;

		transition: 0.4s linear;
		transition-property: width;
	}
}

.container {
	display: flex;
	width: 600px;
	align-items: flex-start;
	column-gap: 60px;
}

.form {
	display: flex;
	flex-direction: column;
	row-gap: 25px;
	flex-grow: 1;
	width: 100%;

	&__buttons {
		display: flex;
		justify-content: space-between;
		gap: 25px;
		width: 100%;
		row-gap: 25px;
	}

	&__row {
		display: flex;
		column-gap: 25px;
	}

	&__column {
		display: flex;
		flex-direction: column;
		width: 50%;
		row-gap: 25px;
	}
}

@media screen and (max-width: 468px) {
	.container {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		row-gap: 10px;
		width: 100%;
	}

	.form {
		width: 100%;
		align-items: center;
		row-gap: 10px;

		&__buttons {
			flex-direction: column;
			width: 100%;
			row-gap: 10px;

			& > button {
				width: 100%;
			}
		}

		&__row {
			flex-direction: column;
			width: 100%;
			align-items: center;
			row-gap: 10px;
		}

		&__column {
			flex-direction: column;
			width: 100%;
			align-items: center;
			row-gap: 10px;
		}
	}
}

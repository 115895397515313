.error-message {
	background-color: var(--sterry-color-negative);
	border: 1px solid rgb(255, 112, 112);
	padding: 10px;
	border-radius: 10px;
	font-size: 16px;
	margin-bottom: 10px;
}

.buttons {
	display: flex;
	gap: 16px;
	justify-content: flex-end;
	margin-top: 24px;
}

.container {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin: auto;
	max-width: 1024px;
	position: relative;

	.title {
		text-align: center;
		margin-bottom: 15px;
		font-size: 40px;
		font-weight: 200;
	}

	.backContainer {
		margin-bottom: 20px;
		color: white;
		display: flex;
		align-items: center;

		svg {
			width: 50px;
			fill: white;
		}
	}

	.tabOption {
		display: flex;
		align-items: center;
		gap: 8px;
		svg {
			width: 35px;
			height: 35px;
			fill: white;
		}
	}

	.form {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 16px;

		& span {
			margin-left: 16px;
		}
	}

	.organisationStorageLabel {
		font-weight: 200;
		margin-bottom: 16px;
	}

	.name-label {
		display: flex;
		justify-content: space-between;
	}

	.buttons {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
		gap: 12px;
	}

	.errorMessageContainer {
		color: var(--sterry-color-negative);
	}

	.button-small {
		width: fit-content;
		margin-top: 4px;
	}

	.drive {
		display: flex;
		flex-direction: column;
		gap: 32px;
		margin-bottom: 10px;

		&__limit {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.upgradeBtn {
				margin-left: 30px;
				white-space: nowrap;
			}
		}

		& > * {
			width: 100%;
		}
		& > button {
			width: 25%;
			white-space: nowrap;
		}
	}
}

.container {
	height: calc(100vh - 75px);
	display: flex;
	align-items: center;
	justify-content: center;

	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 80px;
		border-radius: 8px;
		background-color: #fcfcfc;
		padding: 0px 25px 25px 25px;
		border-top: 8px solid #6e00db;

		@media screen and (max-width: 800px) {
			grid-template-columns: 1fr;
		}
	}

	&__image {
		display: grid;
		grid-template-rows: 75px 1fr;
		& > svg {
			height: 100%;
			width: 100%;
		}

		@media screen and (max-width: 800px) {
			display: none;
		}
	}
}

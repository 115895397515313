.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	& > img {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: calc(100vh - 147px);
		width: 100%;
		object-fit: contain;
	}
}

.button-container {
	display: flex;
	margin-top: 30px;
	user-select: none;
}

.button-dropdown-container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.button-dropdown {
	position: absolute;
	top: 46px;
	left: 0px;
}

.skeletonBone {
	position: relative;
	overflow: hidden;
	height: 12px;
	width: 50%;

	&:not(.animation-off):after {
		content: '';
		animation: loading 1.5s infinite;
		height: 100%;
		left: 0;
		position: absolute;
		background-image: linear-gradient(90deg, transparent, #f3f3f5, transparent);
		right: 0;
		top: 0;
		transform: translateX(-100%);
		z-index: 1;
	}
}

.storybookVariant1 {
	width: 100px;
	height: 100px;
	border-radius: 9999px;
	margin-top: 10px;
}

.storybookVariant2 {
	width: 800px;
	height: 200px;
	border-radius: 7px;
	margin-top: 20px;
}

.storybookVariant3 {
	width: 800px;
	height: 10px;
	border-radius: 7px;
	margin-top: 20px;
}

@keyframes loading {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

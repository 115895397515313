.optionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	white-space: nowrap;

	svg {
		margin-right: 10px;
		width: 32px;
		height: 32px;
	}

	&.paid svg {
		fill: #d2e5ca;
	}
	&.uncollectible svg {
		fill: #e0e0e0;
	}
	&.void svg {
		fill: #e0e0e0;
	}
}

.modalContainer {
	.bodyText,
	.error,
	.input {
		margin-top: 20px;
	}
	.btnContainer {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;

		button {
			margin: 0 10px;
		}
	}
}

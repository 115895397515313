.container {
	width: 200px;
	background-color: var(--side-bar-background-color);
	display: var(--sidebar-display);
	flex-direction: column;
	transition: width 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
	overflow-x: hidden;
	user-select: none;
	justify-content: space-between;
	min-width: 50px;
	height: calc(100vh - 200px);
	box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.45);
}

.container > ul {
	width: 100%;
}

.container > ul li {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 60px;
}

.container > ul li > div {
	border-style: solid;
	border-width: 2px;
}

.active {
	border-color: white;
}

.inactive {
	border-color: transparent;
}

.container > ul li > div:first-child {
	margin-bottom: 5px;
}

/* @media only screen and (max-width: 1024px) {
	.container {
		width: 50px;
	}
}

@media only screen and (max-width: 375px) {
	.container {
		display: none;
	}
} */

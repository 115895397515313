.form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.textfield {
	background-color: var(--sterry-color-grey);
}

.textfield > input {
	color: white;
}

.textfield > input:focus {
	border: 1px solid white;
}

.name {
	color: white;
	margin: 2px 0px 8px 0px;
}

.description {
	margin: 10px 0px 8px 0px;
}

.button {
	background-color: var(--sterry-color-positive);
	color: white;
	margin-top: 14px;
	height: var(--sterry-modal-button-height);
}

.dropdown {
	border: 1px solid #2189ff;
	border-radius: 10px;
	width: fit-content;
	background-color: #2189ff;
}

.dropdown > ul > li {
	padding: 9px 20px;
	background-color: #fff;
	color: #687b8c;
}

.dropdown > ul > li:hover {
	background-color: #2189ff;
	color: #fff;
	cursor: pointer;
}

.dropdown > ul > li:first-child {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.dropdown > ul > li:last-child {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

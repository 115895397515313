.container {
	position: relative;

	.customItemLabelContainer {
		position: absolute;
		font-size: 14px;
		margin-left: 1px;
		background-color: #171717;
		pointer-events: none;
		padding: 12px 16px;
		border-radius: 4px;
		display: flex;
		align-items: center;

		&.icon {
			left: 33px;
			padding-left: 6px;
		}
	}

	.suffixComponentContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;

		.suffixIcon {
			transition: transform 0.2s;
			width: 24px;
			height: 24px;
			fill: white;
			cursor: pointer;

			&.suffixIconDisabled {
				opacity: 0.5;
				cursor: not-allowed;
			}

			&:hover {
				opacity: 0.75;
			}
		}
	}

	.dropdownContainer {
		z-index: 1;
		position: absolute;
		border-top-width: 2px;
		border: 1px solid #575757;
		max-height: 300px;
		overflow-y: scroll;
		background: #171717;
		width: 100%;

		.dropdownItem {
			padding: 12px 16px;
			border-bottom: 1px solid #575757;
			background: #171717;
			cursor: pointer;

			&:last-child {
				border-bottom: none;
			}

			&:hover {
				opacity: 0.75;
			}
		}
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 14px;
}

.innerModalContainer {
	box-shadow: 0px 3px 20px #000000;
	background-color: var(--sterry-modal-background-color);
	border-radius: var(--sterry-modal-border-radius);
	padding: var(--sterry-modal-padding);
	min-width: fit-content;
	margin: 0 20px;
	position: relative;
	display: flex;
	flex-direction: column;
}

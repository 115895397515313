.container {
	display: flex;
	width: 100%;
	align-items: flex-start;
	column-gap: 60px;
}

.form {
	display: flex;
	flex-direction: column;
	row-gap: 25px;
	flex-grow: 1;
	width: 100%;

	&__buttons {
		display: flex;
		justify-content: space-between;
	}
}

@media screen and (max-width: 768px) {
	.container {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		row-gap: 10px;
	}
}

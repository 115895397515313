.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font: normal normal 300 14px/29px Poppins;
}

@media screen and (max-width: 768px) {
	.container {
		flex-direction: column;
		row-gap: 10px;
	}
}

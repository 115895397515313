.container {
	width: 200px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	padding: 7px 0px 7px 0px;
}

.container > svg {
	width: 30px;
	font-size: 20px;
	cursor: pointer;
}

.update {
	fill: white;
}

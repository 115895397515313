.imageSection {
	overflow: hidden;
	border-radius: 0;

	img {
		width: 100%;
	}
}

.imageContainer {
	display: flex;
	flex: 1;
	gap: 30px;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.logo {
	width: 280px;
	padding-top: 30px;
}

.cover {
	display: flex;
	object-fit: fill;
	flex: 1;
	width: 80%;
	align-items: flex-end;

	img {
		object-fit: contain;
	}
}

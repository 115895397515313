.item {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.item > span {
	padding: 5px;
}

.item > svg {
	fill: darkgray;
}

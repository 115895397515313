.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 1100px;
	padding: 0 20px;
	margin-bottom: 50px;

	strong {
		font-weight: 700;
	}

	ul {
		margin: 14px 0;
		padding-left: 40px;
		list-style: disc;
	}

	.heading {
		font-size: 35px;
		text-align: center;
		margin-bottom: 50px;
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
	gap: 14px;
}

.container {
	--modalTitleHeight: 25px;
	height: calc(100% - var(--modalTitleHeight));
	display: flex;
	flex-direction: column;
	border-radius: 10px;
}

.emails-container {
	padding: 10px 15px 0;
	display: flex;
	flex-direction: column;
	border: 1px solid #646a73;
	border-radius: 10px;
	min-height: 300px;
	max-height: 560px;
	overflow-y: scroll;
}

.email-container {
	justify-content: space-between;
	width: 100%;
	align-items: center;
	margin-bottom: 15px;
	display: flex;
}

.invalid-container-user {
	color: #ee7884;
	font-weight: 500;
}

.header-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 10px;
}

.error-text {
	color: #ee7884;
}

.accounts-validity-container {
	margin-top: 18px;
	font-weight: 500;
}

.container {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	height: 100%;
	background-color: #373737;
}

.message-container {
	display: flex;
	height: 100%;
}

.message-container > div {
	margin: auto;
}

.alert {
	background: #3e3e39;
	border: 1px solid #fff5a6;
	min-height: 50px;
	padding: 15px;
	width: 30%;
	margin: 0 auto 30px;
	color: #fff;
}

.logo {
	width: 275px;
}

.container {
	display: flex;
	flex-direction: column;
	padding: 10px;

	.titleContainer {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.bodyContainer {
		color: #cecece;
		font-size: 16px;
		margin-bottom: 32px;
	}

	.footerContainer {
		display: flex;
		justify-content: flex-end;
		& > button {
			margin-left: 10px;
		}
	}
}

.container {
	display: flex;
	align-items: center;

	&--hidden {
		display: none;
	}

	& > ul {
		margin: auto;
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		box-sizing: border-box;
		font-weight: 500;
		column-gap: 30px;
	}

	&__vertical {
		& > ul {
			display: block;
		}
	}
}

.container {
	height: 100vh;
	width: 100vw;
}

.header {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
	height: 56px;
	padding: 0 0 16px 0;
	left: 0;
	right: 0;
	width: auto;
	position: fixed;
	color: white;
	display: flex;
	align-items: center;
	z-index: 30;
}

.preview-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.preview-container > img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}

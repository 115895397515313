.container {
	margin-bottom: 10px;

	.title {
		display: flex;
		align-items: center;
		font-size: 24px;
		font-weight: 200;
		margin: 16px 0 10px;

		svg {
			margin-left: 8px;
		}
	}

	.subTitle {
		font-size: 14px;
		font-weight: 200;
		margin: 10px 0;
	}

	.list {
		display: flex;
		flex-direction: column;

		.driveContainer {
			padding: 8px 0;
			display: flex;
			justify-content: space-between;

			.drive {
				display: flex;
				align-items: center;

				img {
					margin: auto;
					margin-right: 10px;
					width: 40px;
					height: 40px;
					object-fit: contain;
				}
			}
		}
	}
}

.container {
	display: flex;
	flex-direction: row;
	padding: 0 30px;

	// responsive styles
	@media (max-width: 1400px) {
		flex-direction: column;
	}
}

.preSubTransHeader {
	margin-top: 20px;
	margin-bottom: -20px;
	font-size: 24px;
	display: none;

	@media (max-width: 1400px) {
		display: block;
	}
}

.mobileEditedTranslationsLink {
	position: fixed;
	right: 50px;
	top: 77px;
	z-index: 4;
	background: #e0e0e0;
	color: black;
	display: none;
	padding: 10px;
	width: 50px;
	font-size: 18px;
	height: 50px;
	border-radius: 25px;
	align-items: center;
	justify-content: center;
	border: 1px solid #707070;

	&:hover {
		cursor: pointer;
		opacity: 0.75;
	}

	@media (max-width: 1400px) {
		display: flex;
	}
}

.translationDropdown {
	padding: 30px;
	width: 250px;
	margin-left: 12px;

	.disabledDropdown {
		cursor: not-allowed;
		opacity: 0.5;
	}

	.dropdownTitle {
		display: flex;
		align-items: center;
		padding: 10px 0 10px 22px;
		color: #636363;
		font-weight: 500;
		background-color: #e0e0e0;
		border-radius: 7px;

		svg {
			margin-left: 5px;
			fill: #636363;
			width: 26px;
			height: 26px;
		}
	}
}

.openedClassName {
	right: -27;
	max-height: 350px;
	overflow: hidden;
	overflow-y: scroll;
}

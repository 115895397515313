.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.card {
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.card > h1 {
	background-color: #ffb64d;
	color: white;
	padding: 10px;
	text-align: center;
}

.card > div {
	padding: 25px;
	text-align: center;
	font-size: 20px;
}

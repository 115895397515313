.enter {
	opacity: 0;
	transform: scale(0.7);
}
.enterActive {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}
.exit {
	opacity: 1;
}
.exitActive {
	opacity: 0;
	transform: scale(0.7);
	transition: opacity 150ms, transform 150ms;
}

.container {
	display: flex;
	align-items: center;
}

.container {
	border-radius: 5px;
	text-align: center;
	font-size: 15px;
	width: fit-content;
	display: flex;
	align-items: center;
	flex-direction: column;

	&__options {
		display: grid;
		grid-template-columns: auto;
		margin-bottom: 15px;
		margin-top: 15px;
		justify-content: center;

		& > div:first-child {
			border-top-left-radius: 3px;
		}

		& > div:last-child {
			border-bottom-right-radius: 3px;
		}

		& > div:nth-child(6) {
			border-top-right-radius: 3px;
		}

		& > div:nth-child(7) {
			border-bottom-left-radius: 3px;
		}

		& > div {
			cursor: pointer;
			width: 50px;
			height: 50px;
			background-color: rgb(51, 51, 66);
			transition: 0.5s;
		}
	}

	&__display {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border: 2px solid #52616f;
		border-radius: 10px;
		overflow: hidden;
		width: 100%;

		& > input {
			background-color: #262d33;
			color: white;
			line-height: 1.25;
			font-size: 15px;
			padding: 0.5rem 0.75rem;
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
			width: 100%;
			height: 48px;
			border: none;
			border-left: 2px solid #262d33;
		}

		& > div {
			display: block;
			width: 60px;
			height: 48px;
			padding: 3px;
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
		}
	}
}

.zIndex {
	z-index: 2001;
}

.container {
	display: flex;
	flex-direction: column;
}

.container > p {
	margin-top: 10px;
}

.option {
	display: flex;
	align-items: flex-start;
	margin-bottom: 12px;
}

.checkbox {
	margin-right: 10px;
}

.titleContainer p {
	margin: 0;
}

.selectListContainer {
	margin-top: 10px;
}

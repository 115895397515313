.title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.signup-section {
	background-color: #fff;
	color: #494f5d;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	flex-direction: column;
	padding: 0px 35px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow-x: hidden;
	position: relative;
}

.back-arrow {
	position: absolute;
	left: 20px;
}

.back-arrow svg {
	margin-top: 10px;
}

.back-arrow:hover {
	cursor: pointer;
}

@media (max-width: 900px) {
	.signup-section {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}
}

.terms {
	color: #4f5864;
	margin-top: 23px;
	margin-bottom: 24px;
	font-size: 14px;
}

.terms > a {
	color: #6ba8f1;
	font-weight: bold;
	font-size: 14px;
}

.input {
	margin-bottom: 11px;
}

.error-message {
	color: var(--sterry-color-negative);
	text-align: center;
	font-size: 16px;
}

.strength-indicator {
	width: 100%;
	margin-top: 20px;
	margin-left: 10px;
	margin-bottom: 7px;
}

.buttonContainer {
	display: flex;
	justify-content: right;
}

.confirmPasswordInput {
	margin-top: 16px;
}

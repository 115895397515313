.container {
	display: flex;
	justify-content: center;
	align-self: center;
	flex-direction: column;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 4px;
	font-size: 12px;
	gap: 4px;
	width: 95%;

	&__graph-container {
		display: flex;
		width: 100%;
		align-items: center;

		& > svg {
			width: 32px;
			height: 32px;
		}

		&__graph {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			margin-left: 14px;
			height: 8px;
			background-color: #575757;
			border-radius: 10px;

			.usageIndicator {
				opacity: 0.7;
				height: 8px;
				margin-bottom: 10px;
				border-radius: 10px;
				background: linear-gradient(90deg, #4690ff 0%, #2cd4f2 100%);
			}
		}
	}
}

.container {
	position: relative;

	.item {
		display: flex;
		align-items: center;

		& > :first-child {
			margin-left: 10px;
		}
	}
}

.container {
	display: flex;
	flex-direction: column;
	row-gap: 25px;

	& label {
		font: normal normal 300 14px/29px Poppins;
	}

	& > div {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}

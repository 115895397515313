.container {
	padding: 0 20px;
}

.orgTargetContainer {
	display: flex;
	align-items: center;
	svg {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin-right: 10px;
	}
}

.auditActionContainer {
	display: flex;
	align-items: center;

	.icon {
		margin-right: 10px;
		width: 40px;
		height: 40px;
		fill: #e0e0e0;

		&.markAsPaidIcon {
			fill: #4caf50;
		}
	}
}

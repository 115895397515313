.title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.recover-section {
	background-color: #fff;
	color: #494f5d;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	flex-direction: column;
	padding: 0px 35px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow-x: hidden;
	position: relative;
}

.back-arrow {
	position: absolute;
	left: 20px;
}

.inputIcon {
	width: 34px;
	height: 34px;
}

.back-arrow svg {
	margin-top: 10px;
}

.back-arrow:hover {
	cursor: pointer;
}

@media (max-width: 900px) {
	.recover-section {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}
}

.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.enter-email-text {
	margin-top: 22px;
	color: #494f5d;
}

.error-message {
	color: var(--sterry-color-negative);
	text-align: center;
	font-size: 16px;
}

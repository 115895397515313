.container {
	color: rgb(200, 200, 200);
	padding: 10px;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 1;
}

.titleContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 4px;

	.title {
		margin: 8px 0;
		font-size: 22px;
		text-align: center;
	}
}

.buttonsContainer {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}

	.alignToLeft {
		left: 0;
		width: fit-content;
	}

	@media (max-width: 600px) {
		.alignToLeft {
			left: auto;
		}
	}

	.buttonsContainerRight {
		display: flex;
		button {
			margin-left: 10px;
		}
	}

	.buttonsContainerLeft {
		display: flex;
		button {
			margin-right: 10px;
		}

		button:last-child {
			margin-right: 0;
		}
	}
}

.newListItem {
	display: flex;
	align-items: left;

	.sortIcon {
		fill: #fff;
		margin-right: 8px;
		margin-left: -4px;
		width: 20px;
		height: 20px;
	}

	div {
		flex: 1;
		align-items: center;
	}

	span {
		flex: 1;
		margin-left: 10px;
	}
}

.breadcrumb {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 8px;

	.breadcrumb-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.breadcrumb > span {
	cursor: pointer;
}

.button-add {
	@media screen and (max-width: 400px) {
		display: none;
	}
}

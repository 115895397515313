.container {
	display: flex;
	align-items: center;
	font-size: 0.9rem;
	font-weight: 600;
	color: #545454;
	letter-spacing: 0.8px;

	&__accent {
		min-width: 2px;
		width: 4px;
		height: 0.9rem;
		margin-right: 5px;
	}
}

.container {
	display: flex;
	cursor: pointer;
	background-color: transparent;
	border: 0px;
	border-radius: 50%;
	position: relative;

	&__image {
		& svg {
			width: 56px;
			height: 56px;
			margin: auto;
		}

		& img {
			border-radius: 50%;
			border: 1px solid #d7d7d7;
			width: 56px;
			height: 56px;
		}
	}

	&__badge {
		height: 18px;
		position: absolute;
		bottom: 0px;
		right: 5px;
	}
}

.inputText {
	background-color: #f6f6f6;
	color: black;
	line-height: 1.25;
	padding: 0.5rem 0.75rem;
	border: 1px solid #ccc;
	border-radius: 2px;
	width: 100%;
}

.actions {
	display: flex;
	align-items: center;
	height: 100%;
	& > div {
		width: 120px;
		height: 35px;
	}

	.saveBtn {
		margin-right: 10px;
	}
}

.justAdded {
	color: #00b300;
	font-weight: 500;
	display: flex;
	align-items: center;
	animation: bounce-in-and-out 3s ease-in;
	white-space: nowrap;

	@keyframes bounce-in-and-out {
		0% {
			opacity: 0;
		}
		20% {
			opacity: 1;
		}
		80% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}

.itemUpdate {
	.textTo {
		margin-left: 4px;
	}
	.waitingText {
		color: #ccc;
		font-weight: 500;
	}
}

.dropdownItem {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 18;
}

.dropdownItem > svg {
	margin-right: 6px;
}

.container {
	.priceSelectionContainer {
		display: flex;
		width: 100%;
		margin-bottom: 10px;

		.priceItem {
			display: flex;
			align-items: center;

			& > :first-child {
				margin-left: 10px;
			}
		}

		.autocomplete {
			flex: 1;
		}

		.btnContainer {
			& > :first-child {
				margin-right: 15px;
			}

			margin-left: 15px;
			display: flex;
			align-items: flex-end;
			justify-content: right;
		}
	}

	.product {
		margin: 15px 0;
		display: flex;
		align-items: center;

		svg {
			margin-left: 10px;
			width: 20px;
			fill: currentColor;
		}

		.productName {
			color: currentColor;
			display: flex;
			align-items: center;
			margin-left: 10px;
			font-size: 20px;

			svg {
				margin-left: 10px;
				width: 20px;
				height: 20px;
				fill: currentColor;
			}
			

			&:hover {
				opacity: 0.75;
			}
		}
	}
}

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 18px;
}

.container > div {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	width: 100%;
	padding: 0 20px;
	max-width: 1600px;
}

.tile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	margin: 12px;
	border-radius: 5px;
	min-width: 168px;
	color: #000000;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	padding: 20px;

	.name {
		font-size: 20px;
		margin-top: 10px;
	}

	.description {
		font-size: 14px;
		margin-top: 10px;
		color: #858b8f;
		text-align: center;
	}
}

.tile svg {
	width: 120px;
	height: 120px;
	margin-bottom: 10px;
}

.tile > h2 {
	font-size: 27px;
	font-weight: 800;
	margin-bottom: 20px;
}

.buttonContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: left;

	.buttonContainerOptions {
		display: flex;
		flex-wrap: wrap;
	}

	:first-child {
		margin-right: 10px;
	}

	.divider {
		color: #272727;
		margin: 0 10px;
	}

	a {
		color: #0060f0;
		text-decoration: none;

		&:hover {
			opacity: 0.8;
		}
	}
}

.heading {
	font-size: 35px;
	text-align: center;
	margin: 50px;
}

@media (max-width: 768px) {
	.container > div {
		grid-template-columns: repeat(1, 1fr);
	}
}

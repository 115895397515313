.tag {
	border-radius: 8px;
	color: white;
	text-align: center;
}

.teal {
	background-color: #2fbfb6;
}

.grey {
	background-color: #7c8181;
}

.white {
	background-color: #fff;
}

.blue {
	background-color: #c9ddfa;
	color: #31479e;
}

.yellow {
	background-color: #fcf9c7;
	color: #986023;
}

.red {
	background-color: #fce5f4;
	color: #99281f;
}

.green {
	background-color: #d2e5ca;
	color: #3a5d25;
}

.tag.xs {
	padding: 4px 10px;
}

.tag.sm {
	padding: 8px 14px;
}

.tag.md {
	padding: 10px 20px;
}

.container {
	display: flex;
	flex-direction: column;
	background-color: transparent;

	&__title {
		font-size: clamp(18px, 4vmin, 96px);
		font-weight: 600;
		line-height: 1.5;
	}

	&__actions {
		margin-top: 25px;

		& > * {
			display: flex;
			flex-wrap: wrap;
			@media screen and (max-width: 1366px) {
				justify-content: center;
			}
			gap: 16px;
		}
	}

	&__body {
		margin-top: 10px;
	}

	&__footer {
		margin-top: 50px;
	}
}

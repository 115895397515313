.optionsContainer {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	margin-right: 10px;

	& > div > div {
		display: flex;
	}
}

.link {
	color: inherit;
}

.disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

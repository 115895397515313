.popup-container {
	color: white;
	position:absolute;
    transform-origin: center center;
    transform: translate(-50%, -50%);
    z-index: 3;
    opacity: 1;
    box-shadow: rgb(0 0 0 / 15%) 0px 3px 10px;
    overflow-y: auto;
}

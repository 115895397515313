.container {
	background: #474747;
	color: #fff;

	::-webkit-scrollbar {
		height: 5px;
	}
}

.cellContainer {
	display: flex;
	composes: cellPadding from './Table.module.css';

	& span {
		overflow-x: auto;
		max-height: 100px;
	}
}

.row {
	border-top: 0.25px solid #515151;
	display: grid;
	cursor: pointer;
	align-items: center;
}

.row:hover {
	background: #585858;
}

.emptyStateContainer {
	padding: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 20px;
}
.emptyStateIllustration {
	height: 100%;
	max-width: 150px;
	width: 50%;
	margin-bottom: 20px;
}

.document {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: 100%;
}

.form {
	color: #272727;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	.dropdown {
		width: 20%;
		background-color: red;
		height: 47px;
		color: #272727;
		padding: 12px 16px;
		border-radius: 4px;
		background-color: #e7e7e7;
	}

	.phone {
		display: flex;
		gap: 16px;
	}

	& h3 {
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	& > ul {
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin-bottom: 24px;

		& > li {
			margin: 0px;
			align-items: flex-end;

			& > * {
				width: 100%;
			}

			& label {
				display: block;
				width: 100%;

				& input {
					width: 100%;
					display: flex;
					padding: 10px 12px 9px 12px;
					align-items: center;
					gap: 10px;
					border-radius: 4px;
					border: 1px solid rgba(87, 87, 87, 0);
					background: #e7e7e7;
				}
			}
		}
	}

	& hr {
		border: 1px solid #e7e7e7;
		margin: 0px;
		margin-bottom: 5px;
	}
}

.horizontal {
	display: flex;
	flex-direction: row;
	gap: 12px;
	& input {
		width: 100%;
	}
}

.buttons {
	display: flex;
	width: 100%;
	gap: 16px;
	justify-content: space-between;

	& > button {
		width: 100%;
	}
}

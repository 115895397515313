.container {
	width: 837px;
	height: 500px;
	background-color: white;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	display: flex;
	overflow: hidden;
}

.modal {
	align-items: center;
	overflow: auto;
	align-items: center;
}

.modal > div {
	width: unset;
	background-color: #fff;
	padding: 0px;
}

.modal-plus > div {
	margin-top: 50px;
}

@media (max-width: 900px) {
	.container {
		width: unset;
		height: auto;
	}

	.container > section:first-child {
		display: none;
	}

	.container > section:nth-child(2) {
		width: 375px;
		padding: 15px 15px 0px 15px;
	}
}

@media (max-width: 375px) {
	.modal {
		& > div {
			margin-top: unset;
			border-top-left-radius: unset;
			border-top-right-radius: unset;
		}
	}

	.modal-plus > div {
		margin-top: 0px;
	}
}

.container {
	min-width: 600px;
	min-height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.description-container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.description-container > svg {
	width: 50px;
	height: 50px;
}

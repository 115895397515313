.container {
	margin-top: 20px;
	padding: 0 calc(1vmax + 1.8rem);

	.plansContainer {
		display: flex;
		flex-direction: column;

		.singlePlanContainer {
			display: flex;
			align-items: center;
			padding: 10px 0;

			.editableContainer {
				display: flex;
				button {
					margin-left: 20px;
				}

				input {
					width: 100px;
				}
			}

			.pillContainer {
				width: 160px;
			}

			.storageSizeContainer {
				width: 160px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;

				.editIcon {
					margin-left: 10px;

					&:hover {
						cursor: pointer;
						opacity: 0.75;
					}
					width: 20px;
				}
			}
		}
	}
}

.container {
	display: flex;
	flex-direction: column;
	margin-top: 14px;
	overflow-y: auto;
	max-height: 400px;

	& > div {
		display: grid;
		grid-template-columns: 1fr 1fr;

		& > div:nth-child(2) {
			overflow: auto;
			&::-webkit-scrollbar {
				height: 0px;
			}
		}
	}

	.user {
		display: flex;
		min-width: fit-content;
		align-items: center;
		svg {
			margin-left: 10px;
		}
	}
}

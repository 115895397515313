.dropdown {
	cursor: pointer;

	& ul {
		& > li {
			& > div {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				font-weight: 500;
				fill: #fff;

				& > svg {
					max-width: 50px;
					width: 50px;
					margin: 0 auto;
					height: 24px;
					fill: #fff;
				}
				& > div {
					width: 100%;
				}
			}
		}
	}
}

.toolbarContainer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	align-items: center;

	.refreshBtn {
		margin-left: 8px;
	}

	.dropdownIcon {
		width: 28px;
		height: 28px;
		margin-left: 10px;
	}
}

.container {
	background-color: #646a73;
	border-radius: 4px;
	min-height: 56px;
	display: flex;
}

.errorContainer {
	outline: solid var(--sterry-color-negative) 1px;
}

.elementsWrapper {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
	width: 100%;
}

.input {
	border: 0;
	margin-top: 10px;
	width: 100%;
}

.badgeIcon {
	fill: #4f5864;
	width: 20px;
	height: 20px;
}

.badgeIcon:hover {
	cursor: pointer;
	opacity: 0.75;
}

.badgeContent {
	font-weight: 500;
	color: #4f5864;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.badge {
	margin-right: 4px;
	margin-top: 10px;
}

.badge:last-child {
	margin-right: 10px;
}

.container.grey {
	background-color: var(--sterry-color-grey);
}

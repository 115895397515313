.container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.containerSearch {
		margin-top: 5%;
		display: flex;
		width: 100%;
		align-items: flex-start;
		justify-content: center;

		.search {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			width: 50%;
			position: relative;
			min-width: 375px;
			max-width: 1024px;

			.suffixIcons {
				display: flex;
				gap: 15px;
				margin-right: 15px;

				svg {
					height: 24px;
					width: 24px;

					&:hover {
						cursor: pointer;
						opacity: 0.75;
					}
				}
			}

			.poweredByContainer {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 10px;

				img {
					width: 180px;
					height: 22.5px;
				}
			}
		}
	}
	.illustration {
		background: none;
	}
}

.container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
}

.buttons {
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

.container textarea {
	background-color: var(--sterry-color-grey);
	min-height: 150px;
	width: 100%;
}

.error-message {
	background-color: var(--sterry-color-negative);
	border: 1px solid rgb(255, 112, 112);
	padding: 10px;
	border-radius: 10px;
	font-size: 16px;
	margin-bottom: 10px;
}

.domainField input {
	background-color: var(--sterry-color-grey);
	color: #fff;
}

.content {
	overflow: auto;
	max-height: 450px;
	padding: 10px;
	margin: 10px;
}

.driveItemSharedStyles {
	background: #373737 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	position: relative;

	&:hover {
		background: #484848;
	}

	.fileProviderLogo {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.shortcutEl {
		position: absolute;
		translate: transform(-50%, -50%);
		border: 1px solid #ffffff;
		background: rgb(55, 55, 55, 0.8);
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.driveItem {
	padding: 9px 10px;

	.shortcutEl {
		top: 15px;
		left: 15px;
		svg {
			padding: 3px;
		}
	}

	.usageIndicatorContainer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		opacity: 0.7;

		.percentageText {
			font-size: 11px;
			text-align: right;
			margin-right: 4px;
		}

		.usageIndicator {
			height: 5px;
			border-radius: 10px;

			&__low {
				background: #309485;
			}
			&__medium {
				background: #ff8c00;
			}
			&__high {
				background: #ff0000;
			}
		}
	}

	.favouriteContainer {
		position: absolute;
		top: 8px;
		left: 8px;

		.defaultStar {
			display: block;
		}

		.hoverStar {
			display: none;
		}

		&:hover {
			.defaultStar {
				display: none;
			}

			.hoverStar {
				display: block;
			}
		}

		.star {
			width: 25px;
			height: 25px;
		}
	}

	.contentContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.fileProviderLogo {
			top: 8px;
			left: 8px;
			width: 25px;
			height: 25px;
		}

		.driveItemTextContainer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 18px;
			position: relative;

			.driveItemSize {
				position: absolute;
				right: 0;
				top: -16px;
				font-size: 12px;
				color: #b3b3b3;
			}

			.driveItemText {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				color: #ffffff;
				font-size: 13px;
				font-weight: 500;
				letter-spacing: 0px;
				line-height: 20px;
			}
		}

		.driveItemSubText {
			font-size: 12px;
			color: #b3b3b3;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.thumbnailContainer {
			overflow: hidden;
			margin: 0 auto;
			height: 60%;
			max-height: 120px;
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 100px;
			width: 100%;
		}
	}

	.dropdownContainer {
		position: absolute;
		top: 8px;
		right: 8px;
	}

	.loadingImg {
		height: 80px;
		margin-bottom: 10px;
		width: 90%;
		border-radius: 15px;
	}

	.loadingText {
		width: 90%;
		border-radius: 9999px;
	}
}

// mobile stuff
.driveItemTable {
	flex-direction: row-reverse;
	justify-content: space-between;
	padding: 10px 14px;

	.shortcutEl {
		top: 8px;
		left: 8px;

		svg {
			width: 14px;
			height: 14px;
			padding: 2px;
		}
	}

	.dropdownContainer {
		margin-top: 4px;
		margin-left: 4px;
		width: 20px;
	}

	.favouriteContainer {
		display: flex;
		padding-top: 3px;
		width: 30px;
		padding-left: 4px;
		padding-right: 4px;

		.defaultStar {
			display: block;
		}

		.hoverStar {
			display: none;
		}

		&:hover {
			.defaultStar {
				display: none;
			}

			.hoverStar {
				display: block;
			}
		}

		.star {
			width: 25px;
			height: 25px;
		}
	}

	.contentContainer {
		align-items: center;
		width: 100%;
		display: flex;
		flex-direction: row;

		.fileProviderLogo {
			top: 4px;
			left: 4px;
			width: 15px;
			height: 15px;
		}

		.driveItemTextContainer {
			// display: flex;
			display: grid;
			width: 100%;

			.driveItemText {
				color: #ffffff;
				font-size: 14px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.driveItemSize {
				font-size: 11px;
				color: #b3b3b3;
			}
		}

		.driveItemSubText {
			font-size: 12px;
			color: #b3b3b3;
		}

		.thumbnailContainer {
			margin-right: 12px;
			min-width: 50px;
			width: 50px;
			height: auto;
			max-height: 120px;
			display: flex;
			justify-content: center;
			align-items: center;

			& > img {
				max-height: 60px;
				width: 100%;
			}
		}
	}

	.dropdownContainer {
		margin-top: 4px;
	}
}

.extra {
	&__icon {
		position: absolute;
		top: 15px;
		right: 15px;
		display: flex;

		& svg {
			width: 24px;
			margin: auto;
		}
	}
}

.document {
	background-color: #f3f4f5;
}

.heading {
	font-size: 2rem;
	line-height: 2.5rem;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 15px;
	color: #272727;
	font-weight: 300;
}

.container {
	width: 60%;
	display: flex;
	flex: 1;
	justify-items: center;
	margin: auto auto;
	padding: 20px;

	@media (max-width: 768px) {
		width: 100%;
	}

	& > div {
		display: flex;
		flex-direction: column;
		background-color: #ffffff;
		color: #2d2c2b;
		max-width: 1200px;
		border: 1px solid #e0e0e0;
		border-radius: 5px;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
		padding: 0 20px;
		margin: auto auto;
	}

	a {
		color: #0060f0;
		text-decoration: underline;
	}
}

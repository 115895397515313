.container {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	padding-bottom: 32px;
	padding: 0px 35px;

	& > div:last-child {
		margin-bottom: 5px;
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		width: 100%;
		margin-top: 20px;

		& h3 {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
	}
}

.button-permissions {
	& > div {
		justify-content: flex-start !important;
		width: 100%;
	}

	&__body {
		margin-left: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		& svg {
			width: 24px;
			fill: #333;
		}
	}
}

.permissions-link {
	position: absolute;
	width: fit-content;
	cursor: pointer;

	& > div:first-child {
		color: #2189ff;
		margin-top: 15px;
	}
}

.textfield {
	background-color: #eaeaea;
	height: 50px;

	& > svg {
		font-size: 30px;
	}

	& > input {
		color: #687b8c;

		&::placeholder {
			color: #aaaaaa;
		}
	}
}

.textfield-button > div {
	color: white;
	width: 97px;
	min-width: 97px;
}

.button-copy {
	background-color: var(--sterry-color-cancel);
}

.button-send {
	background-color: var(--sterry-color-positive);
	width: 65px;
	height: 56px;
	margin-left: 10px;
	color: #fff;
}

.message-area {
	display: flex;
}

.textfield-message {
	height: 50px;
	width: 348.5px;
	background-color: #687b8c;
	border-radius: 10px;
	display: flex;

	& > div {
		margin: auto;
		color: #fff;
		font-size: 15px;
	}
}

.multi-select-container {
	position: relative;
}

.multi-select {
	background-color: var(--sterry-textfield-background-color) !important;
	max-height: 100px !important;
	overflow-y: auto;
}

.group-select {
	position: absolute;
	top: 60px;
	width: 100%;
	min-height: 200px;
	border-radius: 10px;
	background-color: darkgray;
	color: #687b8c;
	background-color: #eaeaea;
	overflow-y: auto;
	max-height: 200px;

	& > div {
		font-size: 15px;
		padding: 8px 10px;

		&:hover {
			background-color: #d5d5d5;
		}
	}
}

.link-container {
	display: flex;
	justify-content: space-between;
	gap: 5px;
}

.manage-people {
	display: flex;
	justify-content: space-between;

	& > div {
		color: #0060f0;
	}
}

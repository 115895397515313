.title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.email-section {
	background-color: #fff;
	color: #494f5d;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	flex-direction: column;
	padding: 0px 35px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow-x: hidden;
	position: relative;
}

.provider-button-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	margin-top: 9px;
}

.buttonContainer {
	margin-top: 8px;
	display: flex;
	justify-content: right;
}

.signup {
	width: 418px;
	justify-content: center;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	display: flex;
	height: 100%;
}

.email-section .provider-button {
	font-size: 14px;
	width: 110px;
	height: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	margin-bottom: 9px;
}

.email-section .provider-button.email-button {
	width: 100%;
	height: unset;
	border: 0;
}

.email-section .provider-button.email-button:hover {
	cursor: pointer;
}

.email-section-form {
	width: 100%;
	margin-top: 20px;
}

.inputIcon {
	width: 34px;
	height: 34px;
}

.hr {
	margin-top: 14px;
	margin-bottom: 13px;
}

.privacy input {
	border-color: #707070;
}

.privacy > div:nth-child(2) {
	margin-left: 8px;
}

.back {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.recover-password {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	align-items: center;
	justify-content: space-between;
}

.recover-password-text {
	color: #6ba8f1;
	font-size: 16px;
	cursor: pointer;
}

.error-message {
	display: flex;
	color: var(--sterry-color-negative);
	text-align: center;
	justify-content: center;
	font-size: 16px;
	background-color: #f9dadb;
	border: 1px solid #ea3324;
	padding: 15px 0px;
	border-radius: 10px;
	width: 100%;
}

.input-error {
	border: 1px solid var(--sterry-color-negative);
}

.no-account-text {
	color: #4f5864;
	font-size: 16px;
	margin-right: 5px;
}

.no-account-link {
	margin-left: 5px;
	color: #6ba8f1;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
}

.back-arrow {
	position: absolute;
	left: 20px;
}

.back-arrow svg {
	margin-top: 10px;
}

.back-arrow:hover {
	cursor: pointer;
}

.hidden {
	display: none;
}

.visible {
	display: block;
}

@media (max-width: 900px) {
	.login-section {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}

	.login-section .textfield {
		width: 345px;
		min-width: 345px;
	}

	.login-section .textfield:nth-child(1) {
		margin-right: 30px;
	}

	.signup {
		margin-top: 11px;
		width: 375px;
	}
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	position: relative;
	margin-bottom: 50px;

	& a {
		color: inherit;
	}
}

.mainContainer {
	display: grid;
	grid-template-rows: 1fr auto;
	height: 100%;
}

.tile-icons svg {
	width: 100px;
	height: 100px;
}

.title {
	font-size: 35px;
	text-align: center;
	margin-top: 35px;
	margin-bottom: 27px;
}

.container > div {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	width: 100%;
	max-width: 1200px;
	padding: 0 20px;
}

@media only screen and (max-width: 991px) {
	.container > div {
		grid-template-columns: 1fr;
	}
}

.tile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	margin: 12px;
	border-radius: 5px;
	min-width: 168px;
	min-height: 163px;
	max-height: 270px;
	height: 25vw;
	color: #000000;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	padding: 20px;

	.name {
		font-size: 20px;
		margin-top: 10px;
	}

	.description {
		font-size: 14px;
		margin-top: 10px;
		color: #575757;
		text-align: center;
	}
}

.tile svg {
	width: 100px;
	height: 100px;
}

.tile svg:hover {
	filter: opacity(0.8);
}

.topics {
	border-radius: 10px;
	display: flex;
	padding: 30px;
	width: 885px;
	justify-content: space-between;
	flex-direction: column;
	height: 100%;
	background-color: rgba(57, 68, 77, 0.85);
	margin: 12px;
	min-width: 350px;
}

.topics > *:first-child {
	font-size: 24px;
	color: #cccccc;
}

.topics > * {
	line-height: 1;
	font-size: 20px;
}

.topics > *:hover {
	filter: opacity(0.8);
}

.resources {
	border-radius: 10px;
	display: flex;
	padding: 30px;
	justify-content: space-between;
	flex-direction: column;
	width: 279px;
	height: 100%;
	background-color: rgba(57, 68, 77, 0.85);
	margin: 12px;
}

.resources > *:first-child {
	font-size: 24px;
	color: #cccccc;
}

.resources > * {
	line-height: 1;
	font-size: 20px;
}

.resources > *:hover {
	filter: opacity(0.8);
}

@media only screen and (min-width: 991px) {
	.tile svg {
		width: 150px;
		height: 150px;
	}
}

@media only screen and (max-width: 991px) {
	.topics > * {
		font-size: 16px;
	}
	.resources > * {
		font-size: 16px;
	}
}

@media only screen and (max-width: 375px) {
	.tile {
		margin: 8px;
	}
	.resources {
		width: 100%;
	}
}

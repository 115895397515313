.dropdown {
	border-left: 1px solid #c7c7c7;
	height: 46px;
	padding: 0px 10px;
	display: flex;
	align-items: center;
}

.popup {
	border: 1px solid #c7c7c7;
	border-radius: 10px;
	width: fit-content;
	height: auto;
}

.popup > div {
	height: auto;
}

.popup > ul > li {
	padding: 9px 20px;
	background-color: #fff;
	color: #687b8c;
}

.popup > ul > li:hover {
	background-color: #c7c7c7;
	color: #fff;
	cursor: pointer;
}

.popup > ul > li:first-child {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.popup > ul > li:last-child {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.container {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	&__description {
		font: normal normal 300 14px/29px Poppins;

		& h3 {
			font-weight: 600;
		}
	}
}

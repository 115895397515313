.defaultStorageValue {
	opacity: 0.5;
	font-size: 10px;
}

.optionsContainer {
	display: flex;

	& > svg {
		width: 30px;
		height: 30px;
		fill: white;
		cursor: pointer;
		margin-left: 6px;

		&:hover {
			opacity: 0.75;
		}
	}
}

.input {
	width: 100px;
}

.container {
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
	animation: fadein 250ms;
}

.inner-container {
	box-shadow: 0px 3px 20px #000000;
	border-radius: var(--sterry-modal-border-radius);
	margin: 0 20px;
	position: relative;
	display: flex;
	flex-direction: column;

	&--defaultStyles {
		background-color: var(--sterry-modal-background-color);
		padding: var(--sterry-modal-padding);
		width: var(--sterry-modal-width);
	}
}

.closeIconContainer {
	position: absolute;
	top: 16px;
	right: 16px;
	z-index: 4;
}

.icon {
	width: 24px;
	height: 24px;
	cursor: pointer;
	fill: #fff;
}
.icon:hover {
	opacity: 0.75;
}

.inner-container > hr {
	width: 100%;
	margin-top: 16px;
	margin-bottom: 12px;
	border-top: 1px solid var(--sterry-modal-hr-color);
	width: 100%;
}

.title {
	display: flex;
	padding-right: 25px;
	font-size: var(--sterry-modal-title-font-size);
	color: var(--sterry-modal-title-color);
	line-height: 1;
	font-weight: 275;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

// variants
.blue {
	border-top: 8px solid #0063f7;
}

.danger {
	border-top: 8px solid #ff3b3b;
}

.purple {
	border-top: 8px solid #6e00db;
}

.yellow {
	border-top: 8px solid #ffdd00;
}

.transparent {
	border-top: 0px solid transparent;
}

.modalInnerContainer {
	box-shadow: 0px 3px 20px #000000;
	background-color: var(--sterry-modal-background-color);
	border-radius: var(--sterry-modal-border-radius);
	padding: var(--sterry-modal-padding);
	min-width: 800px;
	margin: 0 20px;
	position: relative;
	display: flex;
	flex-direction: column;

	.btnContainer {
		margin-top: 25px;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		width: 100%;

		& > :last-child {
			margin-left: 10px;
		}
	}
}

.container {
	margin-top: 20px;

	& > * {
		margin-bottom: 10px;
	}

	.controlsContainer {
		.alertEl {
			margin-top: 10px;

			.navBtn {
				margin-left: 10px;
			}
		}
		.waitNote {
			margin-top: 10px;
			font-size: 12px;
			opacity: 0.6;
		}
	}
}

.container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;

	& span {
		margin-left: 16px;
	}
}

.agreement {
	margin-top: 24px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}

.buttons {
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

.terms-link {
	border-bottom: 1px solid darkgray;
	cursor: pointer;
}

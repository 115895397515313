@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap');

* {
	box-sizing: border-box;
	font-family: 'Poppins';
	font-weight: 300;
}

a {
	text-decoration: none;
}

.hero-background {
	background-color: rgb(39, 39, 39);
	background-image: radial-gradient(ellipse, rgba(39, 39, 39, 0) 10%, #272727 90%), url('../assets/texture.svg');
}

body {
	background-color: rgb(39, 39, 39);
	background-image: radial-gradient(ellipse, rgba(39, 39, 39, 0) 10%, #272727 90%), url('../assets/texture.svg');
	font-size: 0.875em;
	height: 100vh;
	color: #fff;
	line-height: 1.5;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

::-webkit-scrollbar {
	max-width: 8px;
	border-radius: 10px;
	background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.3);
}

body::-webkit-scrollbar {
	display: none;
}

html,
body,
ul,
ol,
h1,
h2,
h3,
h4,
h5 {
	margin: 0px;
	padding: 0px;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 500;
}

ul,
ol {
	list-style-type: none;
}

img {
	display: block;
}

#root {
	height: 100%;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* hide native input search icon */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	-webkit-appearance: none;
}

:root {
	--modal-background-color: #51606d;
	--modal-title-color: #ccc;
	--modal-padding: 20px;
	--modal-color: #ffffff;
	--page-background-color: #51606d;

	--sterry-modal-background-color: #272727;
	--sterry-modal-border-radius: 8px;
	--sterry-modal-padding: 14px;
	--sterry-modal-title-color: white;
	--sterry-modal-title-font-size: 25px;
	--sterry-modal-width: 500px;

	--sterry-color-cancel: #687b8c;
	--sterry-color-negative: #ff5370;
	--sterry-color-positive: #2189ff;

	--sterry-header-color: rgb(59, 70, 80);
	--sterry-header-height: 90px;

	--sterry-tabs-active-tab-item-background-color: #2189ff;
	--sterry-tabs-border-radius: 10px;

	--sterry-color-grey: #646a73;
}
@media screen and (max-width: 768px) {
	:root {
		--sterry-modal-width: 375px;
	}
}

.container {
	color: var(--sterry-header-color);
	height: var(--sterry-header-height);
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	z-index: 3;
	width: 100%;

	// For mobile phones decrease the height and therefore it's children
	@media screen and (max-width: 400px) {
		height: 75px;
	}

	// The left portion of the header includes both the logo and navigation bar
	&__left {
		z-index: 1;
		display: flex;
		height: 100%;
		width: 100%;

		& > * {
			margin-left: 30px;

			// Adjustments for narrower devices
			@media screen and (max-width: 800px) {
				margin-left: 10px;

				&:nth-child(2) {
					display: none;
				}
			}
		}
	}

	&::after {
		content: '';
		width: 100%;
		height: var(--sterry-header-height);
		@media screen and (max-width: 400px) {
			height: 70px;
		}
		background: rgb(255, 255, 255);
		background: linear-gradient(0deg, rgba(39, 39, 39, 0.1) 50%, rgb(27, 27, 27) 90%);
		top: 0;
		position: absolute;
		display: inline-block;
	}

	&__profile {
		display: flex;
		align-items: center;
		z-index: 1;
		gap: 10px;

		.organisationIcon {
			fill: #fff;
		}

		@media screen and (max-width: 400px) {
			& > *:first-child {
				display: none;
			}

			& > *:nth-child(2) {
				display: none;
			}
		}

		& > svg {
			width: 25px;
			fill: #858b8f;
		}

		&__line {
			content: '';
			border-left: 3px solid #858b8f;
			height: 36px;
			width: 5px;
			margin-left: 10px;
		}
	}
}

.container {
	display: grid;
	grid-template-columns: 20px 50px 150px auto 1fr 40px;
	align-items: center;
	justify-content: flex-start;
	column-gap: 10px;
	width: 100%;
	font: normal normal 300 14px/16px Poppins;

	& > div {
		white-space: nowrap;
	}

	&__image {
		display: flex;
		width: 50px;
		height: 40px;
		justify-content: center;

		& img {
			margin: auto;
			width: 40px;
			height: 40px;
			object-fit: contain;
		}
	}

	&__name {
		position: relative;

		&__usage {
			position: absolute;
			font-size: 10px;
			opacity: 0.75;
		}
	}
	&__overflow-hide {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__ellipsis {
		fill: #a7a7a7;
		width: 30px;
		height: 30px;
		object-fit: contain;
		cursor: pointer;

		&:hover {
			fill: #e7e7e7;
		}

		&--disabled {
			visibility: hidden;
			pointer-events: none;
		}
	}

	&__open {
		fill: #a7a7a7;
		width: 30px;
		height: 30px;
		object-fit: contain;
		cursor: pointer;
		justify-self: flex-end;

		&:hover {
			fill: #e7e7e7;
		}
	}
}

.container {
	display: flex;
	padding: 20px;
	justify-content: center;
	align-items: center;

	@media (max-width: 768px) {
		padding: 0;
	}
}

.faq_button {
	padding: 10px 19px;
	border: 1px solid transparent;
	cursor: pointer;
	font-size: 15px;
	border-radius: 2px;
	color: white;
	background-color: #343a40;
	width: 100%;
}

.mt-10 {
	margin-top: 10px;
}

.faq-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	max-width: 80%;
}

.faq-section a {
	text-decoration: underline;
	margin: 10px;
}

.faq-main-title {
	font-size: 35px;
	text-align: center;
	padding: 20px;
}

.faq-questions {
	display: flex;
	flex: 1;
	justify-content: center;
	min-width: 100%;
	max-width: 100%;
}

.video-frame-container {
	position: relative;
	padding-top: 28.25%;
	height: 50%;
	width: 50%;
	margin: 0 auto;
}

.video-frame {
	border: none;
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
}

.domain-verification {
	b {
		font-weight: 600;
	}

	& > ol {
		list-style-type: decimal;
		margin: unset;
		padding: 0px 10px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-left: 10px;

		& ol {
			list-style-type: disc;
			margin-left: 20px;
		}
	}
}

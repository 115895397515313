.tabs {
	display: flex;

	.tab {
		flex: 1;
		line-height: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin: 0;
		border-bottom: 3px solid transparent;
		padding-bottom: 8px;

		@media (max-width: 768px) {
			width: 100%;
			font-size: 0.8rem;
			padding-left: 0px;
			padding-right: 0px;
			min-width: none;
		}
	}

	.tab.active {
		color: var(--sterry-primary-color);
		border-color: #0060f0;

		@media (max-width: 768px) {
			min-width: none;
		}
	}

	.tab:hover {
		cursor: pointer;
	}

	.tab:not(.active):hover {
		color: var(--sterry-primary-color);
	}
}

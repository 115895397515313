.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #fff;
	color: #272727;

	&__header {
		font-size: 20px;
		padding-bottom: 20px;
		color: #272727;
		user-select: none;
	}

	&__body {
		padding: 0px 60px;
		display: flex;
		flex-direction: column;
	}

	& hr {
		width: 100%;
		height: 1px;
		background-color: #e7e7e7;
		border: none;
		margin: 32px 0px;
	}

	&--hidden {
		display: none;
	}
}

@media screen and (max-width: 768px) {
	.container {
		&__body {
			padding: 0px 10px;
		}
	}
}

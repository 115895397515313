.container {
	position: absolute;

	& > button {
		position: fixed;
		bottom: 58px;
		right: 2px;
		z-index: 1;
	}

	cursor: crosshair;
}

.pen {
	fill: #fff;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100%;
	overflow: hidden;
}

.pen-container {
	position: absolute;
}

.container {
	min-height: 200px;
	width: 300px;
	color: #ffffff;
	background-color: #363636;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 15px;
	user-select: none;
	border-radius: 4px;

	& > h4 {
		width: 100%;
	}
}

.id {
	font-size: 10px;
}

.container {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__buttons {
		justify-content: flex-end;
		display: flex;
		gap: 16px;
		width: 100%;
	}
}

.list {
	background-color: #313131;
	border-radius: 4px;
	border: 1px solid #f7f7f7;
	height: 200px;
	max-height: 200px;
	overflow-y: auto;
	padding: 6px 10px;

	& > li {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding: 4px 0px;

		& > div:first-child {
			max-width: 200px;
			min-width: 200px;
		}

		& svg {
			cursor: pointer;
			width: 24px;
			height: auto;
		}

		& > button {
			height: 25px;
		}
	}
}

.rejected {
	color: #cd2626;
	font-weight: 500;
}

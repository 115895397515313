.container {
	display: flex;
	align-items: center;
	white-space: nowrap;

	.inputContainer {
		flex-wrap: wrap;
		display: flex;
		align-items: center;
		margin-left: 5px;

		.input {
			margin-right: 5px;
			width: 100px;
		}
	}
}

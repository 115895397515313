.innerModalContainer {
	background-color: #fff;
	box-shadow: 0px 3px 20px #000000;
	border-radius: var(--sterry-modal-border-radius);
	padding: var(--sterry-modal-padding);
	width: var(--sterry-modal-width);
	margin: 0 20px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.container {
	color: #000;

	.avatarSelectionContainer {
		.selectAvatarText {
			text-align: center;
			margin: 16px 0 8px;
		}

		.divider {
			width: 100%;
			height: 1px;
			background-color: #e7e7e7;
			margin: 8px 0;
		}

		.avatarList {
			padding: 10px;
			display: grid;
			width: 100%;
			grid-template-columns: repeat(4, 1fr);
			row-gap: 10px;
			column-gap: 10px;

			.avatarContainer {
				margin: 0 auto;
				overflow: hidden;
				width: 78px;
				height: 78px;
				border-radius: 50%;
				outline: 1px solid #e7e7e7;

				&:hover {
					cursor: pointer;
					opacity: 0.75;
				}

				&.selectedAvatar {
					outline: 2px solid #035ff0;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		margin-bottom: 20px;
	}

	.uploadContainer {
		display: flex;
		border: 1px solid #e7e7e7;
		border-radius: 4px;
		justify-content: center;
		position: relative;

		.sizeRecommended {
			position: absolute;
			bottom: 0;
			opacity: 0.5;
			font-size: 13px;
		}

		.imgContainer {
			max-height: 180px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.upload {
			min-width: 250px;
			min-height: 180px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}
	}
}

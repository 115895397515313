.tile {
	position: relative;
	border-radius: 5px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
	width: 200px;
	height: 150px;

	.tile-title {
		position: absolute;
		bottom: 0;
		width: 100%;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
		color: #fff;
		padding: 10px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		overflow: hidden;
	}

	&:hover {
		transition: transform 0.5s ease;
		transform: scale(1.07);
	}
}

.container {
	position: relative;
	margin: 0 auto;
}

[data-tooltip] {
	position: relative;
	z-index: 2;
	cursor: pointer;
}

[data-tooltip]:after,
[data-tooltip]:before {
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
	pointer-events: none;
}
[data-tooltip]:after {
	position: absolute;
	width: 0;
	content: ' ';
	font-size: 0;
	line-height: 0;
}

[data-tooltip]:before {
	padding: 5px 20px;
	border-radius: 3px;
	background-color: #fff;
	color: #000;
	content: attr(data-tooltip);
	text-align: center;
	font-size: 12px;
	white-space: nowrap;
	line-height: 1.2;
	position: absolute;
}

/* top */
[data-tooltip].top:before {
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
}

[data-tooltip].top:after {
	bottom: 26px;
	left: 50%;
	transform: translateX(-50%);
	border-top: 5px solid #fff;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}
/* bottom */
[data-tooltip].bottom:before {
	top: 30px;
	transform: translateX(-50%);
}

[data-tooltip].bottom:after {
	top: 26px;
	transform: translateX(50%);
	border-bottom: 5px solid #fff;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}
/* right */
[data-tooltip].right:before {
	left: 30px;
	top: 50%;
	transform: translate(0, -50%);
}

[data-tooltip].right:after {
	top: 50%;
	left: 26px;
	transform: translate(0, -50%);
	border-right: 5px solid #fff;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
}
/* left */
[data-tooltip].left:before {
	right: 30px;
	top: 50%;
	transform: translate(0, -50%);
}

[data-tooltip].left:after {
	top: 50%;
	right: 26px;
	transform: translate(0, -50%);
	border-left: 5px solid #fff;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
}

[data-tooltip].hover:hover:after,
[data-tooltip].hover:hover:before,
[data-tooltip].active:after,
[data-tooltip].active:before {
	opacity: 1;
}

.inner-container {
	display: flex;
}

@media (max-width: 768px) {
	.hide-on-mobile-devices:after,
	.hide-on-mobile-devices:before {
		display: none;
	}
}

.drivelink-item {
	display: flex;
	align-items: center;
	background-color: white;
	border-radius: 5px;
	padding: 10px;
	border: 1px solid #e7e7e7;
	border-bottom: 5px solid #e7e7e7;
	user-select: none;

	.drivelink-item-icon {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}

	.drivelink-item-text {
		font-weight: 900;
		color: #000000;
	}

	.drivelink-item-name {
		font-weight: 300;
		color: #000000;
	}

	a {
		color: #0060f0;
		cursor: pointer;
	}
}

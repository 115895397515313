.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #51606d;
	justify-content: space-between;
	position: relative;
	height: 100%;
}

.container > img {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 250px);
	width: 100%;
	object-fit: contain;
}

.button-container {
	display: flex;
	margin-top: 30px;
	user-select: none;
}

.button-dropdown-container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.button-dropdown {
	position: absolute;
	top: 46px;
	left: 0px;
}

.update {
	animation: spin 2s linear infinite;
	fill: white;
}

.require-signin {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 230px;
	text-align: center;
}

.require-signin svg {
	width: 100%;
	height: 100%;
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	width: 100%;
}

.textContainer {
	margin: 15px 0 50px;
}

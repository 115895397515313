.modal {
	& svg {
		fill: #414141;
	}
	& > * {
		background-color: #fff;
	}

	& > div {
		width: 800px;

		@media (max-width: 576px) {
			width: 375px;
		}

		& > div {
			&:nth-child(2) {
				color: #414141;
				font-weight: 400;
			}
		}
	}
}

.container {
	margin-top: 23px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 14px;
	width: 100%;
	padding: 6px;

	@media (max-width: 576px) {
		grid-template-columns: 1fr;
	}

	& > div {
		width: fit-content;
		white-space: nowrap;
		color: #000;
		display: flex;
		gap: 14px;
		align-items: center;

		& svg {
			width: 28px;
			height: auto;
		}
	}
}

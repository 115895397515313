.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: white;
}

.body {
	margin-bottom: 40px;
}

.bottomContainer {
	display: flex;
	justify-content: center;
	width: 100%;
}

.buttonWrapper {
	width: 50%;
}

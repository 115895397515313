.modal-right-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.title {
	font-size: 25px;
	color: #4f5864;
	margin-top: 14px;
}

.login-section {
	flex-direction: column;
	padding: 0px 35px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow-x: hidden;
	position: relative;
}

.provider-button-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 9px;
	border: 1px solid #d5d5d5;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 300px;

	.ssoDivider {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 7px 20px;
		opacity: 0.5;
		font-size: 13px;
	}

	.provider-button {
		width: 100%;
		font-size: 12px;
		display: flex;
		align-items: center;
		background-color: #ffffff;
		border: none;
		padding: 0;

		& :hover {
			cursor: pointer;
			opacity: 0.8;
			background-color: #e7e7e7;
		}

		.provider-title {
			display: flex;
			width: 100%;
			flex-direction: row;
			padding: 7px 20px;

			.labelContainer {
				display: flex;
				align-items: center;
			}

			.iconContainer {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 20px;

				svg {
					height: 26px;
					width: 26px;
				}
			}
		}
	}
}

.login-section .inputfields {
	transition-duration: 600ms;
	display: flex;
	width: 600px;
}

.login-section .textfield {
	width: 348px;
	min-width: 348px;
	height: 56px;
	margin: 4px 0px 0px 0px;
}

.login-section .signin-button,
.login-section .signin-button-ready {
	width: 100%;
	height: 56px;
	background-color: #8fc3ff;
	color: white;
	font-size: 16px;
	font-weight: bolder;
	margin-top: 6px;
}

.login-section .signin-button-ready {
	background-color: #2189ff;
}

.signup {
	justify-content: center;
	flex-direction: row;
	align-items: center;
	display: flex;
	text-align: center;
	font-size: 14px;
	width: 100%;

	.no-account-text {
		color: #4f5864;
		font-size: 16px;
		margin-right: 5px;
	}

	.text-button {
		color: #6ba8f1;
		cursor: pointer;
		font-size: 16px;
		font-weight: 500;
		text-align: center;
		user-select: none;
	}
}

.hr {
	margin-top: 14px;
	margin-bottom: 13px;
}

.footer-container {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	margin-top: 19px;
}

.privacy-container {
	font-size: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.privacy {
		display: flex;
		flex: 1;
		flex-direction: row;
		align-items: center;
	}

	.privacy-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		width: 100%;
		font-size: 14px;
	}

	.privacy-toggle {
		display: flex;
		flex: 1;
		justify-items: flex-end;
	}
	& > svg {
		fill: #2189ff;
		cursor: pointer;
	}

	.privacy input {
		border-color: #707070;
	}

	.privacy > div:nth-child(2) {
		margin-left: 8px;
	}
}

.local-button-title {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.local-button-title p {
	margin: 1em 0 1em 0;
}

.local-button-title svg {
	height: 25px;
	margin-right: 3px;
}

.local-button-icon {
	object-fit: contain;
	width: 20px;
	margin-right: 9px;
}

.local-providers {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 10px;
}

@media (max-width: 900px) {
	.login-section {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}

	.login-section .textfield {
		width: 345px;
		min-width: 345px;
	}

	.login-section .textfield:nth-child(1) {
		margin-right: 30px;
	}

	.login-section .textfield:nth-child(2) {
		margin-left: 30px;
	}

	.signup {
		margin-top: unset;
		margin: 10px 0px;
	}
}

@media (max-height: 600px) {
	.provider-button-container {
		height: 100px;
	}
}

.nextCloudLogo {
	transform: scale(1.5);
	fill: #0082c9;
}

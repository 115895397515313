.container {
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

@media screen and (max-width: 768px) {
	.container {
		padding: 0px;
	}
}

.svgIcon {
	width: auto;
	height: 100%;
	border-radius: 50%;
}

.orgImg {
	object-fit: contain;
	width: 100%;
	height: 100%;
}

.container {
	padding: 20px;
	display: flex;
	gap: 20px;
	margin: auto;
	max-width: 1024px;
	position: relative;

	&__left-container {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&__right-container {
		display: grid;
		width: 100%;
	}

	&__loading {
		position: absolute;
		width: 200px;
	}

	&__tabs {
		&__header {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 20px;

			&__buyMoreContainer {
				display: flex;
				align-items: center;

				button {
					margin-left: 10px;
				}

				span {
					margin-left: 5px;
				}
			}

			& > div:first-child {
				margin-bottom: 10px;
				max-width: 300px;
				width: 100%;
			}
		}
	}
}

.title {
	font-size: 25px;
	color: #4F5864;
	margin-top: 14px;
}

.title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nextcloud-form {
	width: 100%;
	margin-top: 20px;
}

.nextcloud-section {
	background-color: #fff;
	color: #494F5D;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	flex-direction: column;
	padding: 0px 35px;
    display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow-x: hidden;
	position: relative;
}

.provider-button-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	margin-top: 9px;
}

.inputIcon {
	width:34px;
	height:34px;
}

.nextcloud-section .textfield {
	width: 348px;
	min-width: 348px;
	height: 56px;
	margin: 4px 0px 0px 0px;
}

.nextcloud-section .textfield > input {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.nextcloud-section .textfield:nth-child(1) {
	margin-right: 28px;
}

.nextcloud-section .signin-button,
.nextcloud-section .signin-button-ready {
	width: 100%;
	height: 56px;
	background-color: #8FC3FF;
	color: white;
	font-size: 16px;
	font-weight: bolder;
	margin-top: 6px;
}

.nextcloud-section .signin-button-ready {
	background-color: #2189FF;
}

.signup {
	width: 418px;
	justify-content: center;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	margin-top: 18px;
	display: flex;
	height: 100%;
}

.nextcloud-section .provider-button {
	color: #404040 !important;
	font-size: 14px;
	width: 110px;
	height: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	background-color: #DCECFF;
	margin-bottom: 9px;
}

.nextcloud-section .provider-button.email-button {
	width: 100%;
	height: unset;
	border: 0;
}

.nextcloud-section .provider-button.email-button:hover {
	cursor: pointer;
}

.error-message {
	color: var(--sterry-color-negative);
	text-align: center;
	font-size: 16px; 
}

.input-error {
	border: 1px solid var(--sterry-color-negative);
}

.no-account-text {
	color: #4F5864;
	font-size: 16px;
	margin-right: 5px;
}

.no-account-link {
	margin-left: 5px;
	color: #6BA8F1;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
}

.back-arrow {
	position: absolute;
	left: 20px;
}

.back-arrow svg {
	margin-top: 10px;
}

.back-arrow:hover {
	cursor: pointer;
}


@media (max-width: 900px) {
	.nextcloud-section {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}

	.nextcloud-section .textfield {
		width: 345px;
		min-width: 345px;
	}

	.nextcloud-section .textfield:nth-child(1) {
		margin-right: 30px;
	}
	

	.signup {
		margin-top: 11px;
		width: 375px;
	}
}
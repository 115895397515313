.container {
	width: 837px;
	height: 600px;
	background-color: white;
	border-radius: 8px;
	display: flex;
}

.modal > div {
	width: unset;
	background-color: #fff;
	padding: 0px;
}

.container {
	& > div {
		width: 70vw;
		height: 70vh;
	}
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	flex-grow: 1;

	& > div:first-child {
		min-height: calc(100% - 52px);

		& > div:last-child {
			overflow-y: auto;
		}
	}
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 50px;
	padding: 5px 0px;
}

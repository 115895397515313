.container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;

	& span {
		margin-left: 16px;
	}
}

.buttons {
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;
	gap: 12px;
}

.accordion {
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: white;
	box-shadow: 0px 0px 5px 0px #ddd;

	.accordion-item {
		border-bottom: 1px solid #ddd;
		color: #000000;

		.accordion-item-title {
			cursor: pointer;
			display: flex;
			gap: 10px;
			align-items: center;
			padding: 10px;

			&:hover {
				background-color: #f2f2f2;
			}
		}

		.accordion-item-content {
			padding: 10px;
			background-color: #f2f2f2;
		}
	}
}

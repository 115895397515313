.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;

	.title {
		font-size: 35px;
		text-align: center;
		margin-top: 35px;
		margin-bottom: 27px;
	}
}

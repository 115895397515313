.document {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: 100%;
}

.container {
	color: #272727;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	& h3 {
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	& p {
		margin: 0px;
	}
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 215px 215px 215px;
	gap: 16px;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		& > img {
			width: 100%;
			height: 178px;
			object-fit: contain;
		}
	}

	& > div:last-child {
		grid-column: 1 / 3;
		grid-row: 3;
	}
}

.buttons {
	display: flex;
	width: 100%;
	gap: 16px;
	justify-content: space-between;

	& > button {
		width: 100%;
	}
}

.list {
	overflow-x: hidden;
	overflow-y: auto;
	width: 95%;
	margin-top: 14px;
	margin-bottom: 14px;

	& > li {
		margin-bottom: 5px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 48px;
		fill: #ffffff;
		gap: 10px;
		cursor: pointer;
		overflow-x: hidden;
		overflow-y: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		position: relative;
		padding: 0px 15px;

		.icons {
			position: relative;
			display: flex;

			& > svg,
			& > img {
				&:first-child {
					width: 32px;
					height: auto;
				}
			}

			&__pro {
				bottom: 5px;
				right: -3px;
				position: absolute;
				width: 20px;
				height: auto;
			}
		}
	}
}

.container {
	display: flex;
	flex-direction: column;

	&__buttons {
		display: flex;
		gap: 14px;
		margin-bottom: 14px;
	}

	& > textarea {
		width: 100%;
		border: 1px solid #ccc;
		margin: 0 0 8px;
		padding: 4px;
		overflow: auto;
		word-break: break-all;
		box-sizing: border-box;
		min-height: 220px;
		max-height: 640px;
		background: #eee;
		white-space: break-spaces;
	}
}

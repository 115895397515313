.container {
	user-select: none;
	overflow: hidden;
	border-radius: var(--sterry-tabs-border-radius);
}

.header {
	display: flex;
	justify-content: space-around;
	min-width: 200px;
	color: white;
	font-size: 20px;
}

.header>div {
	border-right-style: solid;
	border-right-width: 1px;
	border-right-color: #414b57;
	width: 200px;
}

.header>div:last-child {
	border-right-style: none;
}

.container>div:nth-child(2) {
	border-bottom-left-radius: var(--sterry-tabs-border-radius);
	border-bottom-right-radius: var(--sterry-tabs-border-radius);
}

.body {
	background-color: var(--sterry-tabs-active-tab-item-background-color);
}
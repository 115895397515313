.container {
	display: flex;
	padding-left: 5px;
	white-space: nowrap;
	user-select: none;
}

.pill {
	display: flex;
	gap: 5px;
	background-color: #232323;
	color: #fff;
	border-radius: 25px;
	margin-right: 5px;
	font-size: 0.8rem;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;

	.pill-badge {
		background-color: #1d1d1d;
	}

	&:hover {
		background-color: #0063f7;

		.pill-badge {
			background-color: #0050f1;
		}
	}
}

.pill-selected {
	background-color: #0063f7;

	.pill-badge {
		background-color: #0050f1;
	}
}

.pill-badge {
	display: flex;
	justify-items: center;
	align-items: center;
	background-color: #0063f7;
	color: #fff;
	border-radius: 25px;
	font-size: 0.7rem;
	padding-left: 10px;
	padding-right: 10px;
}

.containerMoreDownloads {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	margin-bottom: 60px;
	height: 1300px;

	@media (max-width: 1300px) {
		height: auto;
	}
}

.pageTitleRow {
	margin-top: 50px;
}

.pageTitle {
	font-size: 2.5rem;
	line-height: 1.2;
	text-align: center;
	font-weight: 300;
	margin: 0;
	margin-bottom: 20px;
}

.cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
	justify-items: center;
	gap: 20px;
	color: #000;
	margin-top: 50px;

	& > div {
		width: 32%;
		min-width: 370px;
		max-width: 470px;

		@media (max-width: 1224px) {
			margin-bottom: 50px;
		}
	}
}

.legacyRow {
	margin-top: 80px;
	margin-bottom: 80px;
}

.legacyDownloadsRow {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-top: 30px;
	background-color: red;
}

.tabs {
	display: flex;
	justify-content: center;
	align-items: stretch;
	position: relative;
	flex: 1;
	width: 100%;
	margin-bottom: 30px;

	.tab {
		font-size: 1.2rem;
		line-height: 1;
		text-align: center;
		font-weight: 700;
		margin: 0;
		border-bottom: 4px solid #858b8f;
		padding-bottom: 8px;
		padding-left: 20px;
		padding-right: 20px;

		@media (max-width: 768px) {
			width: 100%;
			font-size: 0.8rem;
			padding-left: 0px;
			padding-right: 0px;
			min-width: none;
		}
	}

	.tab.active {
		color: var(--sterry-primary-color);
		border-bottom: 4px solid #ffffff;

		@media (max-width: 768px) {
			min-width: none;
		}
	}

	.tab:hover {
		cursor: pointer;
	}

	.tab:not(.active):hover {
		color: var(--sterry-primary-color);
	}
}

.tabContent {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	background-color: #ffffff;
	border-radius: 10px;

	.tabAlertMessage {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex: 1;
		width: 100%;
		background-color: #ffd4e9;
		color: #ff5370;
		border-bottom: 1px solid #dddddd;
		padding: 15px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.tabContentItem {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex: 1;
		width: 100%;
		color: #aaaaaa;
		border-bottom: 1px solid #e5e5e5;

		&:last-of-type {
			border-bottom: none;
		}
	}

	.tabContentItemIcon {
		width: 54px;
		height: 54px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 20px;

		& > img {
			width: 44px;
			height: 44px;
		}

		@media (max-width: 768px) {
			width: 44px;
			height: 44px;
			padding-left: 10px;

			& > img {
				width: 34px;
				height: 34px;
			}
		}
	}

	.tabContentItemDetails {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 5px;
		padding-left: 20px;
		padding-right: 20px;
		width: calc(100vw * 0.3);

		@media (max-width: 768px) {
			padding-left: 10px;
			padding-right: 10px;
			width: 100%;
		}
	}

	.tabContentItemName {
		line-height: 1;
		color: #636363;
		text-align: left;
		margin: 0;
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.tabContentItemVersion {
		line-height: 1;
		text-align: left;
		margin: 0;
		min-width: 150px;
	}

	.tabContentItemDownload {
		line-height: 1;
		text-align: left;
		font-weight: 700;
		margin: 0;
		padding-left: 20px;
		padding-right: 10px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		flex-wrap: nowrap;
		gap: 10px;
		width: 150px;
	}

	.tabContentItemDownloadGrayButton {
		display: flex;
		background-color: #e7e7e7;
		color: #000000;
		border-radius: 10px;
		padding: 10px 10px;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
		cursor: pointer;

		@media (max-width: 768px) {
			font-size: 0.8rem;
		}
	}
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: 100%;

	& > *:first-child {
		width: 100vw;
		height: 100%;
	}

	& > *:nth-child(2) {
		min-width: 768px;
	}
}

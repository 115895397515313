.container {
	display: flex;
    border-width: 5px;
	opacity: 0.75;

	border-style: solid;
    border-radius: 260484px;
    animation: rotation 1.1s infinite linear;
}

.default {
	border-top-color: rgb(70, 77, 94);
    border-right-color: rgb(70, 77, 94);
    border-bottom-color: rgb(70, 77, 94);
    border-left-color: #AEB3BC;
}

.xs {
	--size: 16px;
	height: var(--size);
	width: var(--size);
}

.sm {
	--size: 18px;
	height: var(--size);
	width: var(--size);
}
.md {
	--size: 20px;
	height: var(--size);
	width: var(--size);
}
.lg {
	--size: 22px;
	height: var(--size);
	width: var(--size);
}


@keyframes rotation {
	
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.container {
	list-style-type: none;
	margin-left: 0px;

	&__xs {
		& > li {
			font-size: 14px;
			margin-top: 8px;
		}
	}

	&__sm {
		& > li {
			font-size: 16px;
			margin-top: 4px;
		}
	}

	&__md {
		& > li {
			font-size: 17px;
			margin-top: 8px;
		}
	}

	&__lg {
		& > li {
			font-size: 18px;
			margin-top: 12px;
		}
	}
}

.carousel {
	display: flex;
	height: 200px;
	width: 100%;
	overflow: hidden;
	position: relative;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

	.carousel-item {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		transition: transform 0.5s ease-in-out;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		cursor: pointer;

		.carousel-item-title {
			position: absolute;
			bottom: 0;
			width: 100%;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
			color: #fff;
			padding: 10px;
		}

		.carousel-item-title-article {
			position: absolute;
			bottom: 0;
			width: 100%;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
			color: #fff;
			padding: 10px;
			font-size: 1.2rem;
		}
	}

	.carousel-arrow-left {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		svg {
			height: 40px;
			width: 40px;
			fill: #ffffff;
			filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.4));
		}
	}

	.carousel-arrow-right {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		svg {
			height: 40px;
			width: 40px;
			fill: #ffffff;
			filter: drop-shadow(3px 3px 2px rgb(0 0 0 / 0.4));
		}
	}

	.carousel-badge {
		position: absolute;
		top: 10px;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 10px;
		background-color: #0041c9;
		text-transform: uppercase;
		font-size: 0.8rem;
		z-index: 5;
	}
}

.carousel-article {
	height: 300px;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	a {
		font-size: 0.8rem;
		margin-left: 10px;
	}

	> div {
		display: flex;
		flex-direction: column;
		padding-bottom: 15px;
	}

	svg {
		max-width: 340px;
	}
}

.container--light {
	color: #575757;
}

.container--dark {
	color: #fff;
}

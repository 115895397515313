.intro-text {
	max-width: 375px;
	height: 450px;
	overflow: auto;
	margin: 0 auto;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	margin-top: 24px;
}

.container {
	width: fit-content;
	display: flex;
	background-color: #e7e7e7;
	align-items: center;
	padding: 12px 16px;
	gap: 8px;
	border-radius: 4px;
}

.dropdown {
	color: #272727;
	background-color: #e7e7e7;
	cursor: pointer;

	&--opened {
		height: 250px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 285px;
	}

	.item {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	& svg:last-child {
		margin-left: 5px;
	}
}

.container {
	display: flex;
	padding-left: 5px;
	white-space: nowrap;
	user-select: none;
}

.link {
	padding-left: 5px;
}

.link:hover {
	color: white;
	cursor: pointer;
}

.separator {
	padding-left: 5px;
}

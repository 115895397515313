.body h4 {
	font-weight: bold;
	font-size: 14px;
}

.body > div {
	margin-bottom: 10px;
	font-size: 14px;
}

.button-container > div {
	background-color: var(--sterry-color-positive);
}

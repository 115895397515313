.banner {
	color: white;
	width: 100%;
	background-color: #4099ff;
	padding: 10px;
	border-radius: 6px;
	font-size: 18px;
	text-align: center;
	margin-bottom: 10px;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: column;
}

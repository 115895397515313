.container {
	color: #272727;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	padding: 0px;
	width: 1024px;
	height: 768px;
	max-width: unset !important;

	@media screen and (max-width: 1024px) {
		width: 375px;
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		overflow: hidden;

		@media screen and (max-width: 1024px) {
			width: 100%;
			overflow-y: auto;
		}
	}

	&__left {
		display: flex;
		flex-direction: column;
		padding: 34px;
		justify-content: space-between;

		@media screen and (max-width: 1024px) {
			width: 375px;
		}
	}

	&__right {
		overflow: hidden;

		& img {
			transform: rotate(-10.018deg) scale(0.7, 0.7) translate(-330px, -895px);
			overflow: hidden;
			object-fit: cover;
		}

		@media screen and (max-width: 1024px) {
			display: none;
		}

		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	& h3 {
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	& p {
		margin-top: 24px;
		margin-bottom: 24px;
		font-size: 18px;
	}

	& li {
		margin-top: 30px;
	}
}

.footer {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: center;

	& button {
		width: 100%;
		height: 64px;
		font-size: 18px;
	}

	& p {
		margin-bottom: 58px;
	}
}

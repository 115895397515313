.container {
	.link {
		color: currentColor;
		display: flex;
		align-items: center;
		margin-top: 20px;

		svg {
			margin-right: 5px;
			fill: currentColor;
		}
		&:hover {
			opacity: 0.75;
		}
	}
	.priceTypeContainer {
		display: flex;
		margin-top: 20px;
		margin-bottom: 10px;
		justify-content: center;

		& :first-child {
			margin-right: 10px;
		}

		& > button {
			width: 20%;
			min-width: 100px;
		}
	}

	.controlBtns {
		display: flex;
		margin-top: 25px;
		justify-content: right;

		.createPriceBtn {
			margin-left: 10px;
		}
	}

	.tieredPriceContainer {
		display: flex;
		align-items: center;
		flex-direction: column;

		.singleCurrencyTierContainer {
			display: flex;
			flex-direction: column;
			margin-top: 10px;

			.newTierBtnContainer {
				display: flex;
				justify-content: center;
				margin-top: 10px;
				margin-bottom: 20px;
			}

			.singleTier {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.inputs {
					margin-top: 6px;
					display: flex;
					flex-direction: row;

					.firstUnit {
						width: 350px;
					}

					& > * {
						margin-right: 10px;
					}

					.closeIcon {
						margin-top: 8px;
						cursor: pointer;
						fill: white;
						width: 90px;
						height: 30px;

						&:hover {
							opacity: 0.75;
						}
					}
				}
			}
		}
	}

	.perUnitContainer {
		width: 100%;
		margin-top: 40px;

		.newRowButton {
			margin: 15px auto;
		}
		.singleFlatPrice {
			margin-top: 10px;
			display: flex;
			align-items: center;

			&:first-child .dropdownAndIconContainer {
				margin-top: 29px;
			}

			.dropdownAndIconContainer {
				margin-left: 10px;
				display: flex;
				align-items: center;

				.defaultCurContainer {
					margin: 0 10px;
					width: 50px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					& > :first-child {
						margin-bottom: 4px;
					}
				}

				.closeIcon {
					cursor: pointer;
					margin-left: 8px;
					fill: white;
					width: 25px;
					height: 25px;

					&:hover {
						opacity: 0.75;
					}
				}
			}
		}
	}
}

.modalInnerContainer {
	box-shadow: 0px 3px 20px #000000;
	background-color: var(--sterry-modal-background-color);
	border-radius: var(--sterry-modal-border-radius);
	padding: var(--sterry-modal-padding);
	min-width: 650px;
	width: auto;
	margin: 0 20px;
	position: relative;
	display: flex;
	flex-direction: column;
	max-height: 90%;
	overflow-y: auto;
}

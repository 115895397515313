.container {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	padding-bottom: 32px;
	padding: 0px 35px 32px 35px;

	& img {
		width: 32px;
	}
}

.container > div {
	width: 100%;
}

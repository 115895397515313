.innerContainerClass {
	width: 90%;
	height: 90%;

	box-shadow: 0px 3px 20px #000000;
	border-radius: var(--sterry-modal-border-radius);
	margin: 0 20px;
	position: relative;
}

.container {
	width: 100%;
	height: 100%;

	.messagesContainer {
		margin-top: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.statusContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		width: 100%;

		.logoicon {
			max-width: 300px;
		}

		.spinnerIcon {
			margin-top: 30px;
			width: 40px;
			height: 40px;
		}

		.errorContainer {
			max-width: 50%;
			margin-top: 30px;
			width: 100%;

			.btnContainer {
				display: flex;
				justify-content: center;
				align-items: center;

				.retryBtn {
					margin-top: 30px;
				}
			}
		}
	}
}

.canvasContainer {
	width: 100%;
	height: 100%;
}

.container {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;

	& > * {
		max-width: 375px;
	}

	& button {
		font-weight: 700;
		min-width: 70%;
		width: 70%;
	}

	&__header {
		display: flex;
		align-items: center;
	}
}

.multiple-buttons {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	gap: 14px;
}

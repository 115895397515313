.titleContainer {
	display: flex;
	min-width: fit-content;
	justify-content: space-between;

	.btnContainer {
		margin-left: 10px;
		align-items: center;
		display: flex;
	}
}

.container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 10px;

	& > button {
		width: 100%;
	}
}

.container {
	display: flex;
	column-gap: 14px;

	> * {
		@media (max-width: 500px) {
			width: 100%;
		}
	}
}

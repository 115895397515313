.container {
	background-color: rgba(57, 68, 77, 0.85);
	border-radius: 10px;
	margin: 0 auto;
	min-height: 100px;
	margin-top: 50px;
	width: 50%;
	z-index: 1;
	padding: 20px;
	flex: 1;
	text-align: center;
}

.btn-container {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	max-width: 200px;
	gap: 10px;

	& > div {
		width: 100%;
		margin: 0px 5px;
	}

	@media only screen and (max-width: 480px) {
		flex-direction: column-reverse;

		& > div {
			margin: unset;
			&:last-child {
				margin-bottom: 5px;
			}
		}
	}
}

.btn-danger button {
	background-color: var(--sterry-color-negative);
}

.btn-success button {
	background-color: var(--sterry-color-positive);
}

.container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 1520px;
	margin: 0 auto 20px auto;
	padding-left: 20px;
	padding-right: 20px;
}

.portalCustomFooter {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: pink;
	padding-top: 50px;
	padding-bottom: 50px;

	@media (max-width: 568px) {
		padding-top: 25px;
		padding-bottom: 25px;
	}
}

.feature-button-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	max-width: 860px;
	width: 100%;
	padding-top: 25px;
	padding-bottom: 25px;
	margin: auto auto;
}

.page-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 860px;
	flex: 1;
	margin: auto auto;
	background-color: #ffffff;
	border-radius: 5px;

	@media (max-width: 568px) {
		height: auto;
		margin-left: 10px;
		margin-right: 10px;
	}

	.feature-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		color: #000000;
		padding: 25px;

		@media (max-width: 568px) {
			padding: 10px 0 0 0;
		}
	}

	.content-title {
		display: flex;
		flex-direction: column;
		width: 100%;
		font-size: xx-large;
		font-weight: 700;
	}

	.content-body {
		display: flex;
		flex-direction: column;
		width: 100%;
		font-size: 1rem;
	}
}

.search-container {
	display: flex;
	flex-direction: column;
	width: 60%;
	align-items: center;
	justify-content: center;
	padding-top: 50px;
	padding-bottom: 50px;

	@media (max-width: 568px) {
		width: 100%;
	}

	@media (max-width: 768px) {
		width: 100%;
	}

	.search-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.search {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
		position: relative;
		border: 1px solid #ccc;
	}

	.textfield {
		background-color: #0e0e0e;
	}

	.search-adornment {
		display: flex;
	}

	.search-adornment > div:first-child {
		border-right: 1px solid #ccc;
	}

	.search-adornment > div {
		width: 50px;
		display: flex;
	}

	.search-adornment svg {
		margin: auto;
		fill: #fff;
		width: 35px;
		height: 35px;
	}

	.search-suggestions {
		display: flex;
		width: 100%;
		padding-top: 5px;

		@media (max-width: 568px) {
			flex-direction: column;
			font-size: 0.8rem;
		}

		.intro {
			display: flex;
			flex: 1;
		}

		.powered-by-container {
			img {
				width: auto;
				height: 20px;
			}

			@media (max-width: 568px) {
				display: flex;
				flex: 1;

				img {
					height: 14px;
				}
			}
		}
	}
}

.content-container {
	display: flex;
	flex: 1;
	grid-gap: 20px;
	width: 100%;

	@media (max-width: 568px) {
		flex-direction: column;
	}

	.content {
		display: flex;
		flex: 1;
		flex-direction: column;
		position: relative;

		@media (max-width: 568px) {
			padding-bottom: 40px;
		}

		.section-container {
			display: flex;
			flex-direction: column;
			position: relative;
			overflow-x: auto;
			flex: 1 150px;
			margin-bottom: 20px;
		}

		.section-container::-webkit-scrollbar {
			display: none;
		}

		.tiles-container {
			display: flex;
			grid-gap: 20px;
			overflow-x: auto;
			overflow-y: hidden;
			flex-wrap: nowrap;
			position: absolute;
		}
	}

	.sidebar {
		display: flex;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(1, 1fr);
		flex-direction: column;
		width: 35%;
		min-width: 300px;
		height: 600px;

		@media (max-width: 568px) {
			width: 100%;
			height: 600px;
		}

		.sidebar-container {
			display: flex;
			flex-direction: column;
			background-color: #232323;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			border-radius: 5px;
			height: calc(100% - 35px);

			.tabs-container {
				margin-top: 10px;
			}
		}

		.button-more-container {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 5px;
		}
	}
}

.release-notes-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding: 10px;

	.release-notes {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
		height: 100%;
		min-height: 500px;
	}

	.release-notes-day {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		font-weight: 600;
		margin-bottom: 15px;
	}

	.release-notes-description {
		display: flex;
		flex-direction: column;
		font-weight: 200;
	}

	ul.release-notes-list {
		margin-bottom: 15px;

		li {
			margin-bottom: 2px;
			font-size: 0.8rem;
		}
	}
}

.container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #272727;
	padding: 50px 0px;

	& h3 {
		line-height: 4;
		width: 100%;
		text-align: start;
	}

	& > div {
		margin-top: 15px;
		display: flex;
		flex-direction: column;
		width: 100%;

		& label {
			font-weight: 200;
		}
	}

	& button {
		margin-top: 15px;
	}
}

.container {
	.hiddenDrives {
		cursor: pointer;
		display: flex;
		align-items: center;

		svg {
			margin-left: 6px;
		}
	}
}

.cancelBtn {
	margin-right: 10px;
}

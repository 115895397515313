.container {
	background-color: white;
	border-radius: 4px;
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: space-between !important;
	height: 100%;
	color: #4f5864;
	row-gap: 14px;
	padding: 15px;
}

.title {
	font-size: 25px;
	color: #4f5864;
	margin-top: 7px;
	margin-bottom: 7px;
	text-align: center;
}

.back {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 56px;
	color: #2189ff;
	background-color: #e9e9e9;
	font-weight: 600;
	font-size: 16px;
	width: 418px;
	cursor: pointer;
}

.container {
	width: 300px;
	color: #ffffff;
	background-color: #363636;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px;
	user-select: none;
	border-radius: 4px;
	align-items: flex-start;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		gap: 8px;
		font-size: 14px;
		height: 42px;

		&__title {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		& svg {
			fill: #ffffff;
			max-width: 24px;
			min-width: 24px;
			cursor: pointer;
		}
	}

	&__domains {
		font-size: 12px;
		font-weight: 400;
		margin-top: 16px;
		width: 100%;

		& > div {
			display: flex;
			justify-content: space-between;
			align-items: center;

			& svg {
				fill: #ffffff;
				max-width: 24px;
				min-width: 24px;
				cursor: pointer;
			}
		}
	}

	&__callback {
		font-size: 12px;
		font-weight: 400;
		margin-top: 16px;
		width: 100%;

		& h5 {
			font-weight: 500;
		}

		&__value {
			display: flex;
			align-items: center;
			gap: 8px;

			& > input {
				width: 100%;
			}

			& > svg {
				fill: #ffffff;
				max-height: 24px;
				min-height: 24px;
				cursor: pointer;
			}
		}
	}

	& hr {
		width: 100%;
		border-color: #707070;
	}
}

.list {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 5px;

	& > li {
		display: flex;
		gap: 10px;
		font-size: 14px;
		align-items: center;
		justify-content: space-between;

		& > button {
			height: 25px;
		}

		& > div {
			overflow-x: hidden;
			text-overflow: ellipsis;
		}
	}
}

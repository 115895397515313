.container {
	width: 300px;
	display: flex;
	flex-direction: column;
	background-color: #363636;
	padding: 15px;
	border-radius: 4px;
	border-top: 8px solid #6e00db;

	.buttons {
		margin-top: 16px;
		display: flex;
		justify-content: space-between;
		gap: 16px;

		& > * {
			width: 100%;
			white-space: nowrap;
		}
	}
}

.userLicensesLimitCustomValue {
	color: yellow;
}

.idColumnContainer {
	display: flex;
	align-items: center;

	.badge {
		margin-left: 10px;
	}
}

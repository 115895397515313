.form {
	font-size: 14px;
	color: white;
	padding: 10px;
}

.form > div {
	display: flex;
	align-items: center;
	margin-bottom: 1.25em;
}

.form > div > label {
	width: 25%;
}

.form > div > label > span {
	margin-left: 10px;
	color: rgb(255, 0, 0);
	font-weight: bold;
}

.form > div > div,
.form > div > input {
	width: 75%;
	background-color: #51606d;
	color: white;
	line-height: 1.25;
}

.form > div > input {
	padding: 0.5rem 0.75rem;
	border: 1px solid #ccc;
	border-radius: 2px;
}

.genericSelectList {
	position: relative;
	width: 100%;
	background-color: #fff;
	border-radius: 4px;
	color: #51606d;
	border: 1px solid #51606d;
	padding: 7px 10px;
}

.dropdownArea {
	display: flex;
	width: 100%;
	background-color: #fff;
	padding: 4px 8px;
	border-radius: 4px;
}

.dropdownOptionLight {
	background-color: #fff;
	padding: 4px 8px;
	color: #000;
}

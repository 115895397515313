.messagesContainer {
	display: flex;
	flex-direction: column;

	.singleMessage {
		font-size: 12px;
		margin-top: 4px;
		display: flex;
		align-items: center;

		&--error-icon {
			fill: #ff5370;
		}

		&--info-icon {
			fill: #4099ff;
		}

		svg {
			margin-right: 4px;
			width: 14px;
			height: 14px;
		}
	}
}

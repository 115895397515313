.container {
	max-width: 584px;
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 16px;
	color: white;
	margin-top: 30px;
	text-align: center;
}

.container a {
	font-size: 15px;
	color: white;
}

.logo {
	width: 320px;
	margin-bottom: 20px;
}

.login {
	padding: 0px 0px 35px 0px;
	font-size: 24px;
}

.button-container {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 15px;
}

.button-container > button {
	padding: 10px 19px;
	width: 220px;
	color: #fff;
	margin: 0px 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	font-size: 15px;
	height: 46px;
	position: relative;
}

.button-container > button:focus {
	outline: 0;
}

.button-container > button:hover {
	cursor: pointer;
}

.button-container > button > svg {
	margin-left: 10px;
}

.cancel-button {
	border: 1px solid #ff5370;
	background-color: #ff5370;
}

.ok-button {
	border: 1px solid rgb(102, 187, 106);
	background-color: rgb(102, 187, 106);
}

@media only screen and (max-width: 420px) {
	.logo {
		width: 300px;
	}
}

.container {
	display: none;
	flex-direction: column;
	position: absolute;
	background-color: #fff;
	left: 0;
	top: 0;
	width: 348px;
	border: solid 1px #2189ff;
	border-radius: 10px;
	user-select: none;
	padding: 15px;
	height: 150px;
	z-index: 15;
}

.container > h3 {
	margin-bottom: 10px;
}

.open {
	display: flex;
}

.title {
	color: #687b8c;
	font-weight: 800;
	line-height: 3;
}

.container {
	width: 100%;

	height: 20px;
	overflow: hidden;
	position: relative;

	svg {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-50%, -50%);
	}
}

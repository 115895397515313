.container {
	min-height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;

	.initialImageContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.imageContainer {
			max-width: 300px;
			position: relative;

			.button {
				position: absolute;
				top: 0px;
				right: 0px;
				transform: translate(50%, -50%);
			}

			& > img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.controls {
			width: 100%;

			.secondRow {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 26px;
			}

			.firstRow {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin-top: 4px;

				.fileName {
					display: flex;
					align-items: center;
					font-size: 12px;
					width: 100%;
					justify-content: left;
				}
			}
		}

		.messagesContainer {
			margin-top: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.upscaledContainer {
		.upscaledImageContainer {
			max-width: 350px;

			.imgLoader {
				width: 350px;
				height: 100px;
			}

			.img {
				height: 100%;
				width: 100%;
				box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			}
		}

		.upscaledResult {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-top: 16px;

			.old {
				opacity: 0.7;
				text-decoration: line-through;
			}
			.new {
				font-size: 18px;
				font-weight: bold;
			}
		}
		.upscaledControls {
			margin-top: 16px;
			display: flex;
			justify-content: center;

			& > button:last-child {
				margin-left: 10px;
			}
		}
	}
	.notUpscaled {
		display: flex;
		justify-content: center;
		margin-top: 36px;
	}
}

.titleContainer {
	display: flex;
	align-items: center;
	& > svg {
		margin-left: 10px;
	}
}

.container {
	margin-bottom: 10px;

	.title {
		display: flex;
		align-items: center;
		font-size: 24px;
		font-weight: 200;
		margin: 16px 0 10px;

		svg {
			margin-left: 8px;
		}
	}

	.subTitle {
		font-size: 14px;
		font-weight: 200;
		margin-top: 10px;
	}
}

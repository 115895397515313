.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	& input,
	label {
		font: normal normal 300 14px/21px Poppins;
	}

	&--md {
		.inputContainer {
			.inputEl {
				font-size: 14px;
				padding: 12px 16px;
			}

			.prefixContainer {
				margin-left: 8px;
				margin-right: -8px;
			}

			.icon {
				margin-left: 8px;
				margin-right: -8px;
				width: 28px;
				height: 28px;
			}
		}
	}

	&--sm {
		.inputContainer {
			.inputEl {
				font-size: 14px;
				padding: 6px 10px;
			}

			.prefixContainer {
				margin-left: 8px;
				margin-right: -8px;
			}

			.icon {
				margin-left: 8px;
				margin-right: -8px;
				width: 28px;
				height: 28px;
			}
		}
	}

	// variants
	&--dark {
		.inputContainer {
			background: #171717;
			border: 1px solid #575757;
			color: #fff;

			input,
			textarea {
				color: #fff;
			}
		}
		.singleMessage {
			color: #bbbbbb;
		}
	}

	&--light {
		.label {
			color: #272727;
		}
		.inputContainer {
			background-color: #e7e7e7;
			border: 1px solid #e7e7e7;
			color: #272727;

			.inputEl {
				color: #272727;
				&::placeholder {
					color: rgba(39, 39, 39, 0.5);
				}
			}
		}
		.singleMessage {
			color: #272727;
		}
	}
	//

	// events
	&--focused {
		.inputContainer {
			box-shadow: 0px 0px 0px 2px #4099ff;
			border: 1px solid #4099ff;
		}
	}

	&--error {
		.inputContainer {
			border-color: #dd2222;
		}
	}

	&--disabled {
		opacity: 0.5;
		input::placeholder {
			font-weight: 200;
		}
	}

	.label {
		font-size: 14px;
		margin-bottom: 8px;
	}

	.inputContainer {
		display: flex;
		transition: box-shadow 250ms, border 250ms;
		border-radius: 4px;
		align-items: center;

		.prefixContainer {
			display: flex;
			align-items: center;
		}

		.icon {
			fill: currentColor;
		}

		.clearIcon {
			cursor: pointer;
			fill: #fff;
			position: absolute;
			right: 0px;
			margin-right: 12px;
			width: 24px;
			height: 24px;
		}

		.inputEl {
			display: block;
			background: none;
			outline: none;
			width: 100%;
			border: none;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.container {
	border-radius: 4px;
	border: 1px solid #ffffff;
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
	outline-color: transparent;
	text-decoration: none;
	transition-duration: 200ms;
	transition-property: background-color, border-color, color, fill, stroke;

	&:focus {
		box-shadow: 0 0 0px 1px;
	}

	&:disabled {
		cursor: not-allowed;
	}

	.buttonInnerContainer {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.childrenExists {
		.buttonInnerContainer > svg {
			margin-right: 5px;
		}
	}

	.buttonInnerContainer > svg {
		fill: currentColor;
	}

	&.navigation-disabled {
		pointer-events: none;
	}
}

.icon {
	margin-right: 5px;
	fill: currentColor;
}

// Elevation
.elevation {
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

// Variants
.dark {
	border-color: #272727;
	background: #272727;

	&:hover:not(:disabled) {
		background: #3d3d3d;
		border: 1px solid #3d3d3d;
	}

	&:disabled {
		border: 1px solid #7d7d7d;
		color: #7d7d7d;
	}
}

.light {
	border-color: #e7e7e7;
	background: #e7e7e7;
	color: #272727;

	&:hover:not(:disabled) {
		filter: brightness(1.02);
	}

	&:disabled {
		opacity: 0.38;
	}
}

.outline {
	border-color: #272727;
	background: #ffffff;
	color: #272727;

	&:hover:not(:disabled) {
		filter: brightness(1.02);
	}

	&:disabled {
		opacity: 0.38;
	}
}

.grey {
	background: #474747;
	border: 1px solid #474747;

	&:focus {
		border: 1px solid transparent;
	}

	&:hover:not(:disabled) {
		background: #575757;
		border: 1px solid #575757;
	}

	&:disabled {
		border: 1px solid #333333;
		background: #333333;
		color: #7d7d7d;
	}
}

.transparent {
	background: transparent;
	border-color: #ffffff;
	color: #ffffff;

	&:disabled {
		opacity: 0.5;
	}
}

.blue {
	background: #0063f7;
	color: #ffffff;
	border-color: #0063f7;

	&:hover:not(:disabled) {
		border: 1px solid #5283f8;
		background: #5283f8;
	}

	&:disabled {
		border: 1px solid #224077;
		color: #798cad;
		background: #224077;
	}
}

.warning {
	background: #ffb64d;
	color: #000;
	border-color: #ffb64d;

	&:hover:not(:disabled) {
		background: #ffcc7d;
	}

	&:disabled {
		opacity: 0.38;
	}
}

.blue-gradient {
	background: linear-gradient(45deg, #3c38f0 0%, #007d99 100%);
	color: #ffffff;
	border-color: transparent;

	&:hover:not(:disabled) {
		opacity: 0.75;
	}

	&:disabled {
		opacity: 0.4;
	}
}

.danger {
	background: #cd2626;
	color: #ffffff;
	border-color: #cd2626;

	&:hover:not(:disabled) {
		background: #ff5c5c;
	}
}

.pro {
	border: transparent;
	background: transparent linear-gradient(76deg, #5632d4 0%, #9030e1 100%) 0% 0% no-repeat padding-box;

	&:hover:not(:disabled) {
		background: transparent linear-gradient(76deg, #502ec4 0%, #862dd1 100%) 0% 0% no-repeat padding-box;
	}

	&:disabled {
		opacity: 0.38;
	}
}

// Sizes
.xs {
	padding: 8px 10px;
	font-size: 14px;

	.buttonInnerContainer > svg {
		margin-top: -2px;
		margin-bottom: -2px;
		width: 20px;
		height: 20px;
	}
}

.sm {
	padding: 8px 12px;
	font-size: 14px;

	.buttonInnerContainer > svg {
		margin-top: -2px;
		margin-bottom: -2px;
		width: 20px;
		height: 20px;
	}
}

.md {
	padding: 12px 16px;
	font-size: 14px;

	.buttonInnerContainer > svg {
		margin-top: -3.5px;
		margin-bottom: -3.5px;
		width: 28px;
		height: 28px;
	}
}

.lg {
	padding: 12px 16px;
	font-size: 16px;

	.buttonInnerContainer > svg {
		margin-top: -4px;
		margin-bottom: -4px;
		width: 33px;
		height: 33px;
	}
}

.xl {
	padding: 16px 24px;
	font-size: 18px;

	.buttonInnerContainer > svg {
		margin-top: -4.5px;
		margin-bottom: -4.5px;
		width: 36px;
		height: 36px;
	}
}

.oval {
	border-radius: 30px;
	width: fit-content;
	padding: 5px 10px;
	white-space: nowrap;
	border: 1px solid transparent;
}

// storybook
.liElement {
	margin-top: 20px;
	button {
		margin-right: 10px;
	}
	display: flex;
	align-items: center;
	justify-content: center;
}

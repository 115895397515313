.container {
	display: flex;
	justify-content: center;
	width: 100%;
}

.prompt {
	background-color: #313941;
	border-radius: 28px;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: black;
	margin-top: 50px;
	padding-right: 1%;
	padding-left: 1%;
}

.mail-svg {
	padding-top: 30px;
}

.title {
	color: white;
	font-size: 26px;
	padding: 20px;
}

.body {
	color: white;
	padding: 20px;
	font-size: 16px;
}

.prompt > hr {
	border: solid 1px rgba(0, 0, 0, 0.2);
	width: 100%;
}

.button-container {
	display: flex;
	padding: 20px;
}

.button-container > button {
	color: #fff;
	padding: 10px;
	border-radius: 8px;
	font-size: 14px;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	border-style: none;
	cursor: pointer;
	max-height: 50px;
	width: 150px;
	outline: none;
	margin: 5px;
}

.games {
	background-color: #3a4;
	color: white;
}

.lynxcloud {
	background-color: #1976d2;
}

.cloud-svg {
	margin-right: 5px;
}

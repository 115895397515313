.container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	&__actions {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
		gap: 20px;

		& button,
		a {
			width: max-content;
		}
	}

	&__description {
		width: 100%;
		font: normal normal 300 14px/29px Poppins;

		& h3 {
			font-weight: 600;
			display: flex;
			align-items: center;
			gap: 5px;
		}
	}

	table.striped {
		width: 100%;
		border-collapse: collapse;
		margin: 20px 0;

		th,
		td {
			padding: 10px;
			text-align: left;
		}

		th {
			background-color: #f7f7f7;
			font-weight: bold;
		}
	}

	table.striped tr:nth-child(odd) {
		background-color: #f9f9f9;

		td {
			border-bottom: 1px solid #eaeaea;
		}
	}

	table.striped tr:nth-child(even) {
		background-color: #ffffff;
	}
}

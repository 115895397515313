.openedClassName {
	width: 300px;
	max-height: 350px;
	overflow: hidden;
	overflow-y: scroll;
	border-radius: 5px;
	border-color: #000000;
}

.languageMenuItem {
	display: flex;

	& > div {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		color: #ffffff;
	}

	&--selected {
		background-color: rgba(255, 255, 255, 0.1);
	}
}

li.languageMenuItem {
	&:hover {
		background-color: #0063f7;
	}
}

.icon {
	cursor: pointer;
}

.languageMenuHeader {
	position: sticky;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
	border-bottom: 1px solid #ffffff;
	background-color: #272727;
	align-items: center;

	svg {
		margin-top: 5px;
		fill: #ffffff;
		width: 20px;
		height: 20px;
	}
}

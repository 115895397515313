.container {
	display: flex;
}

.container > div canvas {
	background-color: transparent;
	max-width: 100%;
	max-height: calc(100vh - 200px);
	width: unset !important;
	height: unset !important;
}

.container {
	display: flex;
	flex-direction: row;
	padding: 10px;
	min-width: 250px;
	border-left: 4px solid #fff;
	border-bottom: 1px solid #707070;
	background-color: #333;
}

.containerCentered {
	align-items: center;
}

.toast {
	padding-right: 10px;
	margin-bottom: 10px;
	min-width: 250px;
	border: 1px solid #707070;
	border-radius: 4px;
}

.childrenContainer {
	flex: 1;
}

.timeContainer {
	font-weight: 500;
	font-size: 13px;
	padding: 0 12px 0 16px;
	color: #fff;
}

.icon {
	margin-right: 10px;
	width: 24px;
	height: 24px;
}

.information > svg {
	fill: #4099ff;
}
.success > svg {
	fill: #06c270;
}

.warning > svg {
	fill: #ffcc00;
}

.error > svg {
	fill: #ff3b3b;
}

.viewed {
	opacity: 0.5;
}

.notViewed {
	cursor: pointer;
	// opacity? maybe if it was viewed or not
}

.container.notViewed:hover {
	opacity: 0.75;
}

.container.information {
	border-left-color: #4099ff;
}

.container.success {
	border-left-color: #06c270;
}

.container.warning {
	border-left-color: #ffcc00;
}

.container.error {
	border-left-color: #ff3b3b;
}

/* specific */
.subscribeNewsletterButtonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	svg {
		fill: #06c270;
		margin-top: 3px;
		height: 28px;
		width: 28px;
		animation: fadein 800ms;

		@keyframes fadein {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
	}

	> div {
		cursor: pointer;
		margin-top: 4px;
		padding: 3px 10px;
		font-size: 14px;
		font-weight: 500;
		&:hover {
			opacity: 0.75;
		}
	}

	> div:first-child,
	div:nth-child(2) {
		color: #8e9cad;
	}
	> div:last-child {
		background: #4099ff;
		color: #fff;
		border-radius: 4px;
	}
}

.disabledButton {
	opacity: 0.5;
	cursor: not-allowed;
}

.linkCloudDrivesLink {
	color: #4099ff;
}

.driveStorageLimitExceededContainer {
	display: flex;
	align-items: center;

	.driveStorageLimitExceeded {
		display: flex;
		margin-left: 5px;
		margin-right: 5px;

		.icon {
			margin-right: 4px;
		}
	}
}

.enableSharePointsContainer {
	.controlsWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.link {
			color: #4099ff;
		}
		.buttons {
			margin-top: 10px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			.cancelBtn {
				margin-left: 6px;
			}

			.successIcon {
				fill: #06c270;
				margin-top: 3px;
				height: 28px;
				width: 28px;
				animation: fadein 800ms;

				@keyframes fadein {
					from {
						opacity: 0;
					}
					to {
						opacity: 1;
					}
				}
			}
		}
	}
}

.organisationInvite {
	display: flex;
	align-items: center;

	.title {
		margin-right: 4px;
	}

	a {
		color: #4099ff;
		margin-left: 10px;
	}
}

.container {
	display: none;
	flex-direction: column;
	position: absolute;
	background-color: #fff;
	left: 0;
	top: 0;
	width: 348px;
	border: solid 1px #2189ff;
	border-radius: 10px;
	padding: 0px 15px;
	user-select: none;
	z-index: 10;
}

.open {
	display: flex;
}

.title {
	color: #687b8c;
	font-weight: 800;
	line-height: 3;
}

.option {
	line-height: 3;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.option-selected {
	color: #2189ff;
}

.option svg {
	margin-right: 10px;
}

.option:hover {
	font-weight: 500;
}

.container {
	border-radius: var(--sterry-button-border-radius);
	background-color: var(--sterry-button-background-color);
	height: 50px;
	width: 100%;
	color: inherit;
	font-weight: 400;
}

.container > button:hover {
	filter: brightness(1.04);
}

.container > button {
	border-radius: var(--sterry-button-border-radius);
	background-color: inherit;
	border-style: none;
	border-width: 0px;
	width: 100%;
	height: 100%;
	color: inherit;
	font-size: var(--sterry-button-font-size);
	font-weight: inherit;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
}

.adornment {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.container > button:disabled,
.container > button[disabled] {
	filter: opacity(0.5);
}

.container > button:disabled:hover,
.container > button[disabled]:hover {
	cursor: not-allowed;
	filter: brightness(1);
	filter: opacity(0.5);
}

.elevation {
	box-shadow: var(--sterry-button-box-shadow);
}

.blue {
	background-color: #2189ff;
	color: white;
}

.grey {
	background-color: var(--sterry-color-cancel);
	color: #ffffff;
}

.danger {
	background-color: var(--sterry-color-negative);
	color: #ffffff;
}

.container {
	text-align: center;
	color: white;
	position: relative;
}

.container > h3 {
	font-size: 18px;
}

.container > p {
	font-size: 16px;
}

.button {
	padding: 10px 19px;
	width: 100%;
	border: 1px solid #4099ff;
	background: #4099ff;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	font-size: 15px;
	height: 46px;
}

.button-group {
	display: flex;
}

.button-group > .button {
	margin: 10px 10px 0px 10px;
	cursor: pointer;
}

.button-cancel {
	border: 1px solid #ff869a;
	background: #ff869a;
}

.button-okay {
	border: 1px solid #2ed8b6;
	background: #2ed8b6;
}

.copy {
	display: flex;
	align-items: center;
}

.copy > * {
	width: 100%;
}

.copy > hr {
	border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.copy-input {
	border-radius: 10px;
	font-size: 16px;
	border: none;
	background-color: rgba(255, 255, 255, 0.7);
	color: rgb(60, 60, 60);
	padding: 8px;
	width: 80%;
}

.copy-section {
	display: flex;
	justify-content: center;
	margin-top: 12px;
}

.third-party {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.third-party > * {
	margin: 5px;
}

.userbutton-item {
	display: flex;
	align-items: center;
	background-color: white;
	border-radius: 5px;
	padding: 10px 20px;
	border: 1px solid #e7e7e7;
	border-bottom: 5px solid #e7e7e7;
	user-select: none;
	cursor: pointer;

	.userbutton-item-icon {
		position: relative;
		width: 50px;
		height: 50px;
		margin-right: 10px;

		img,
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}

	.userbutton-item-text {
		font-weight: 900;
		color: #000000;
	}

	.userbutton-item-name {
		font-weight: 300;
		color: #000000;
	}

	a {
		color: #0060f0;
		cursor: pointer;
	}
}

.container {
	margin-top: 10px;

	& > div {
		display: grid;
		grid-template-columns: 1fr 1fr;

		& > div:nth-child(2) {
			overflow: auto;
			&::-webkit-scrollbar {
				height: 0px;
			}
		}
	}
}

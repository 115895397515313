.noDataContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-width: 400px;
	padding: 20px;
}

.noDataIcon {
	max-width: 60%;
	margin-bottom: 10px;
}

.noDataText {
	font-weight: 500;
	margin-bottom: 20px;
	font-size: 16px;
}

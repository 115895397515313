.container {
	display: flex;
	flex-direction: row;
}

.label {
	align-items: center;
	display: block;
	margin-left: 10px;
}

.labelContainer {
	cursor: pointer;
	display: flex;
	width: 100%;
}

.input {
	opacity: 0;
	position: absolute;
}

.icon {
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.radioDot {
	fill: #458bf9;
	display: none;
}

.radioOutline {
	stroke: #757575;
	fill: #fff;
}

.labelContainerChecked {
	.radioDot {
		display: block;
	}
	.radioOutline {
		stroke: #458bf9;
	}
}

// storybook
.storybookContainer {
	padding: 20px;

	> div {
		margin-bottom: 20px;
	}
}

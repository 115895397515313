.dropdown {
	height: auto !important;
	margin-right: 10px;
}

.popup {
	border: 1px solid #2189ff;
	border-radius: 10px;
	width: fit-content;
	background-color: #2189ff;
	height: auto;
}

.popup > div {
	height: auto;
}

.popup > ul > li {
	padding: 9px 20px;
	background-color: #fff;
	color: #687b8c;
}

.popup > ul > li:hover {
	background-color: #2189ff;
	color: #fff;
	cursor: pointer;
}

.popup > ul > li:first-child {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.popup > ul > li:last-child {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

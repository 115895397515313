.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
}

.container {
	position: relative;
	z-index: unset;
	user-select: none;
}

$border-radius: 4px;

.children-container {
	transition: visibility 0.1s linear, opacity 0.1s linear;
	position: absolute;
	right: 0px;
	margin-top: 6px;
	background: #272727;
	width: auto;
	border: 1px solid #707070;
	color: white;
	cursor: pointer;
	border-radius: $border-radius;
	z-index: 10;

	&--light {
		background-color: #e7e7e7;
		color: #272727;
		border: 1px solid #575757;

		& li {
			&:hover {
				background: #dddddd;
			}
		}
	}

	&--left {
		left: 0px;
		right: unset;
	}
}

.hidden {
	visibility: hidden;
	opacity: 0;
}

.visible {
	visibility: visible;
	opacity: 1;
}

.fullWidth {
	width: 100%;
}

.direction-up {
	bottom: 0;
}

.titleContainer {
	& > div {
		display: flex;
	}

	&:hover {
		opacity: 0.7;

		&:has(button) {
			// prevent double hover effect with the button
			opacity: 1;
		}
	}
}

button.titleContainer,
.dropdownDisabled .titleContainer {
	opacity: 0.5;
	pointer-events: none;

	&:hover {
		opacity: 1;
	}
}

.chevronContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.arrow {
	transition: transform 0.2s;
	fill: #000;
	width: 20px;
	height: 20px;
}

.children > li {
	&:hover {
		background: #303034;
	}

	&:last-child {
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		padding-bottom: 8px;
	}

	&:first-child {
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
		padding-top: 8px;
	}

	padding: 5px 10px;
	white-space: nowrap;
	align-items: center;
}

.star-rating {
	display: inline-flex;
	gap: 5px;
}

.star {
	fill: #cecece;
	height: 34px;
	width: 34px;
	cursor: pointer;
}

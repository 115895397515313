.container {
	width: 100%;
	background-color: #fff;
	background-color: #272727;
	user-select: none;

	display: flex;
	flex-direction: column;

	:disabled {
		opacity: 0.5;
	}

	.label {
		margin-bottom: 5px;
	}

	.inputWrapper {
		display: flex;
		align-items: center;

		& input {
			width: 100%;
			cursor: pointer;
		}

		.valueContainer {
			margin-left: 10px;
		}
	}
}

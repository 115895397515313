.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: #fff;
	width: 100%;
	max-width: 1000px;
	border-radius: 8px;

	& > li:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-top: 11px solid #6e00db;

		& > hr:first-child {
			visibility: hidden;
			margin-top: 0px;
		}
	}

	& > li:last-child {
		padding-bottom: 32px;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;

		& > hr:last-child {
			visibility: hidden;
		}
	}
}

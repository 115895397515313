.headersSwitchContainer {
	margin-bottom: 4px;

	.title {
		font-size: 20px;
	}
	.header {
		display: flex;
	}
}

.proContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.proSource {
		margin-top: 4px;
		font-size: 10px;
	}
}

.container > div {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.container > ul {
	width: 100%;
	height: 305px;
	border: 1px solid #eaeaea;
	border-radius: 10px;
	padding: 12px;
	overflow-y: auto;
}

.container > ul li {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 30px;
}

.container > ul li > svg {
	width: 24px;
	margin-right: 15px;
	margin-left: 6px;
}

.container > ul li > div {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container > div:first-child {
	margin-bottom: 4px;
}

.dropdown {
	border: 1px solid #2189ff;
	border-radius: 10px;
	width: fit-content;
	background-color: #2189ff;
}

.dropdown > ul > li {
	padding: 9px 20px;
	background-color: #fff;
	color: #687b8c;
	user-select: none;
}

.dropdown > ul > li:hover {
	background-color: #8ec2ff;
	color: #fff;
	cursor: pointer;
}

.dropdown > ul > li:first-child {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.dropdown > ul > li:last-child {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.dropdown-current-item {
	background-color: #4387f7 !important;
	color: #fff !important;
}

/** permission icons */
.admin {
	fill: #8fc3ff;
}

.viewer {
	fill: #91c08d;
}

.unread {
	fill: #ee7884;
}

.read {
	fill: #ffcf6e;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	color: white;
	margin-top: 10px;

	& > * {
		width: 100%;
	}

	& > label {
		margin-top: 10px;
		text-align: start;

		&:first-child {
			margin-top: unset;
		}
	}

	& h4 {
		margin: 8px 0px;
	}

	&__records {
		display: grid;
		grid-template-columns: 100px 1fr;
		grid-template-rows: auto;

		& b {
			font-weight: 500;
		}

		& span {
			margin-left: 2px;
			color: #f7f7f7;
			display: flex;
			align-items: center;

			& textarea {
				width: 100%;
				background-color: #f7f7f7;
				border-radius: 4px;
			}

			& svg {
				fill: #f7f7f7;
				width: 20px;
				height: auto;
				margin-left: 10px;
				cursor: pointer;
			}
		}
	}
}

.buttons {
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;
	gap: 12px;
}

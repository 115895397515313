.inputText {
	background-color: #f6f6f6;
	color: black;
	line-height: 1.25;
	padding: 0.5rem 0.75rem;
	border: 1px solid #ccc;
	border-radius: 2px;
	width: 100%;
}

.inputNumber {
	background-color: #f6f6f6;
	color: black;
	line-height: 1.25;
	padding: 0.5rem 0.75rem;
	border: 1px solid #ccc;
	border-radius: 2px;
	width: 60%;
}

.actions {
	display: flex;
}

.actions .spacer {
	margin-left: 10px;
	margin-right: 10px;
}

.link-action {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}

.link-action:hover {
	color: #0056b3;
	text-decoration: underline;
}

.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.booleanContainer {
	display: flex;
	min-width: 120px;
	align-items: center;
	justify-content: space-around;

	:first-child {
		margin-right: 10px;
	}
	> div {
		height: 30px;
	}
}

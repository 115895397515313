.container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	height: auto;
	column-gap: 10px;

	a {
		text-decoration: none;
		font-size: 0.8rem;
		padding: 0.5rem;
		width: fit-content;
		border-radius: 4px;
		transition: all 0.2s ease-in-out;
		border: 1px solid transparent;

		@media screen and (max-width: 768px) {
			font-size: 0.7rem;
			line-height: 1;
		}
	}
}

.container--light {
	color: #272727;

	a {
		color: #272727;

		&:hover {
			border: 1px solid #d6d6d6;
			color: #272727;
			background-color: #d6d6d6;
			opacity: 0.8;
		}
	}
}

.container--dark {
	color: #f7f7f7;

	a {
		color: #f7f7f7;

		&:hover {
			border: 1px solid #d6d6d6;
			color: #272727;
			background-color: #d6d6d6;
			opacity: 0.8;
		}
	}
}

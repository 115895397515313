.container {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	& hr {
		width: 100%;
		border-color: #707070;
		margin-bottom: 0px;
	}

	&__message {
		display: flex;
		button {
			margin-left: 8px;
		}
		align-items: flex-end;
		justify-content: flex-end;
	}
}

.bulk {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 14px;

	& button {
		width: fit-content;
	}
}

.title {
	font-size: 25px;
	color: #4F5864;
	margin-top: 14px;
	margin-bottom: 7px; 
}

.title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.verification-section {
	background-color: #fff;
	color: #494F5D;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	flex-direction: column;
	padding: 0px 35px;
    display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow-x: hidden;
	position: relative;
}

.back-arrow {
	position: absolute;
	left: 20px;
}

.back-arrow svg {
	margin-top: 10px;
}

.back-arrow:hover {
	cursor: pointer;
}

@media (max-width: 900px) {
	.verification-section {
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
	}
}

.paragraph > div {
    color: #494F5D;
    font-size: 14px;
}

.paragraph > div:first-child {
    font-weight: bold;
    margin-top: 15px;
}
.thumbnailIcon {
	width: 100%;
	height: 100%;
}

.providerIcon-onedrive {
	width: 80%;
}
.providerIcon-lynxcloud,
.providerIcon-google,
.providerIcon-nextcloud,
.providerIcon-dropbox,
.providerIcon-onedriveSharepoint {
	max-height: 100%;
	width: 70%;
}
.providerIcon-organisation {
	width: 80%;
	height: auto;
	fill: #fff;
}

.recentFilesLogo-nextcloud {
	fill: #0082c9;
}

.thumbnailFileIcon {
	fill: #fff;
}

.fileThumbnail {
	object-fit: contain;
	height: 100%;
}

.dropdownItem {
	display: flex;
	justify-content: flex-start;
	width: 100%;

	> svg {
		width: 25px;
		height: 25px;
		fill: #fff;
		margin-right: 15px;
		padding-right: 3px;
	}
}

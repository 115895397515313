.container {
	width: 108px;
	display: flex;
	background-color: #171717;
	align-items: center;
	padding: 12px 16px;
	gap: 8px;
	border-radius: 4px;
	& > div {
		width: 200px;
		& > div svg {
			fill: #f7f7f7;
		}
	}
	height: 47px;
	border-radius: 4px;
	border: 1px solid #575757;
}

.dropdown {
	color: #f7f7f7;
	background-color: #171717;
	cursor: pointer;

	&--opened {
		height: fit-content;
		overflow-x: hidden;
		overflow-y: auto;
		background-color: #171717;
		color: #f7f7f7;

		& li {
			display: flex;
			gap: 10px;
			align-items: center;
			padding-right: 12px;

			&:hover {
				background-color: rgba(255, 255, 255, 0.1);
			}
		}
	}
}

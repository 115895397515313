.container {
	background-color: #eee;
	min-height: 100%;
	height: auto;

	&__charts {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		align-content: flex-start;

		& > * {
			margin: 10px;
		}
	}
}

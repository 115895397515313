.container {
	background-color: #323232;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	justify-items: center;
	padding: 8px;

	& > svg {
		width: 33px;
		height: 33px;
		cursor: pointer;
	}
}

.pagination {
	width: 115px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 18px;
	font-weight: 200;
	user-select: none;

	& > svg {
		width: 20px;
		font-size: 20px;
		cursor: pointer;

		&:first-child {
			transform: scaleX(-1);
		}
	}

	&__page_numbers {
		width: 25px;
		text-align: center;
	}
}

.controls {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
	gap: 12px;

	& svg {
		width: 35px;
		height: 35px;
	}

	.upscaleBtn {
		svg {
			margin-left: 5px;
		}
	}
}

.pen {
	border-radius: 4px;
	opacity: 0.75;
	border: 1px solid transparent;

	&--active {
		border-color: #fff;
		background-color: rgba(255, 255, 255, 0.1);
	}
}

.details {
	font-size: 16px;
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.icon {
	&:hover {
		opacity: 0.75;
	}
}

.container {
    position: absolute;
    background-color: #FFFDE5;
    color: #494F5D;
    font-size: 16px;
    padding: 13px;
    border-style: solid;
    border-width: 1px;
    border-color: #B7B7B7;
    top: 200px;
    left: 31px;
    width: 356px;
    z-index: 2;
}

.container > div:first-child {
    margin-bottom: 24px;
}

.container .header > span:first-child {
    font-weight: bold;
}

@media (max-width: 900px) {
    .container {
        left: 20px;
        width: 335px;
    }
}
.container {
	flex: 1;
	position: relative;
	padding: 10px 0px;

	.sectionContainer {
		display: flex;
		align-items: center;

		.tooltipContainer {
			margin-left: 4px;

			.sectionContainerIcon {
				width: 22px;
				height: 22px;
				fill: #fff;
				opacity: 0.7;
			}
		}
	}

	.emptyDirectory {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-size: 18px;

		.subText {
			font-size: 12px;
			opacity: 0.8;
		}

		& > svg {
			padding: 40px;
			max-width: 500px;
			width: 100%;
			height: auto;
		}
	}
}

.listContainer {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

	@media screen and (max-width: 454px) {
		justify-content: center;
		grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
	}

	gap: 15px;
	margin: 0 15px;

	.sectionContainerNewRow,
	.sectionContainer {
		grid-column: 1 / -1;
	}

	.linkDrive {
		&:hover {
			cursor: pointer;
			opacity: 0.7;
		}

		text-decoration: none;
		white-space: nowrap;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 60%;
		margin: 16px auto;
		background: #4099ff;
		color: #fff;
		padding: 2px 8px;
		border-radius: 2px;
		font-size: 12px;
		font-weight: 500;
	}
}

.listContainerMobile {
	display: grid;
	grid-template-columns: minmax(200px, 1fr);
	grid-auto-rows: 70px;
	gap: 2px;
	margin: 0 15px;

	.sectionContainerNewRow {
		display: none;
	}

	.linkDrive {
		&:hover {
			cursor: pointer;
			opacity: 0.7;
		}

		text-decoration: none;
		white-space: nowrap;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #4099ff;
		color: #fff;
		padding: 2px 8px;
		border-radius: 2px;
		font-size: 12px;
		font-weight: 500;
	}

	& > div:first-child {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	& > div:last-child {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
}

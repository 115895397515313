.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 20px);
}

.selection {
	border-radius: 10px;
	height: 50px;
	border: 1px solid #fff;
	font-size: 16px;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	user-select: none;
	cursor: pointer;

	&__icon {
		display: flex;
		align-items: center;
		margin-left: 10px;
		height: 36px;
		margin-top: 3px;

		& svg {
			width: 36px;
			max-height: 30px;
		}
	}

	&__title {
		margin-left: 20px;
	}

	&__beta {
		width: 48px;
		height: 24px;
		border-radius: 5px;
		background-color: #2189ff;
		color: #fff;
		text-align: center;
		position: absolute;
		right: 40px;
	}
}

.button-container {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.buttonsContainer {
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	left: 50%;
	width: 80%;
	transform: translateX(-50%) translateY(-50%);
}

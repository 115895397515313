.list {
	display: flex;
	flex-direction: column;
	padding: 20px 0;

	.permissionContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 6px;
		padding-top: 6px;
		border-top: 1px solid #515151;

		&:first-child {
			border-top: none;
			padding-top: 0;
		}

		.enabled {
			opacity: 0.7;
		}

		.permission {
			display: flex;
			font-size: 8px;
			flex-direction: column;

			.label {
				font-size: 17px;
			}
		}
	}
}

.modalIcon {
	width: 26px;
	height: 26px;
	margin-right: 10px;
	fill: currentColor;
}

.container {
	background-color: white;
	border-radius: 20px;
	padding: 0px 35px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	flex-direction: column;
	row-gap: 10px;

	& > * {
		width: 100%;
	}

	& form {
		row-gap: 10px;
	}

	& > h3 {
		font-size: 25px;
		color: #4f5864;
		margin-top: 14px;
		margin-bottom: 7px;
		text-align: center;
	}
}

.back {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 56px;
	color: #2189ff;
	background-color: #e9e9e9;
	font-weight: 600;
	font-size: 16px;
	width: 418px;
	cursor: pointer;
}

.container {
	background: transparent linear-gradient(256deg, #fdfdff 0%, #f3fafd 45%, #b592f6 100%) 0% 0% no-repeat padding-box;
	padding: 45px;
	display: flex;
	height: 100%;

	& > div {
		position: relative;
		margin: auto;
		width: 100%;
	}
}

.copy {
	position: absolute;
	top: 6px;
	right: 6px;
	cursor: pointer;
	fill: #4b535e;
	transform: scale(0.9);
}

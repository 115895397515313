.container {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: black;
}

.video-container {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

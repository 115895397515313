.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	height: 100%;
	width: 100%;

	& > *:first-child {
		margin: auto;
		max-width: 100%;
		height: auto;
		max-height: calc(100vh - 150px);
		object-fit: contain;
	}

	& > *:nth-child(2) {
		width: 100%;
	}
}

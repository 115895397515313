.container {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
}

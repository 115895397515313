.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: transparent;

	&__title {
		font-size: clamp(18px, 4vmin, 96px);
		font-weight: 600;
		margin-top: 6px;
		line-height: 1.5;
	}

	&__body {
		font-size: clamp(18px, 2vmin, 24px);
		margin-top: 6px;
	}

	&__custom,
	&__action {
		margin-top: 6px;
		font-weight: 600;
		font-size: clamp(18px, 1.8vmin, 24px);
	}

	&__custom {
		margin-top: 32px;
	}
}

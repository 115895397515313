.container {
	position: absolute;
	background-color: #303034;
	max-width: 750px;
	border-radius: var(--sterry-modal-border-radius);
	height: auto;
	min-width: 280px;
	color: #fff;
	z-index: 1002;
	top: 81px;
	overflow: hidden;
	right: 2px;
	display: flex;
	flex-direction: column;
	border-style: solid;
	border-width: 1px;
	border-color: var(--sterry-color-grey);
	box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

.listContainer {
	max-height: 50vh;
	overflow: auto;
}

.textHeader {
	padding-left: var(--sterry-modal-padding);
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
}

.seeRecentActivity {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #4099ff;
	padding: 16px;
	font-weight: 600;
}
.seeRecentActivity:visited {
	color: #4099ff;
}

.container {
	position: relative;
	display: flex;
	align-items: center;
}

.input:hover {
	cursor: pointer;
}

.input[type='checkbox'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 18px;
	height: 18px;
	background-color: white;
	border-style: solid;
	border-color: #c7c7c7;
	border-width: 2px;
	border-radius: 2px;
}

.input[type='checkbox']:checked {
	background-image: url('./ios-checkmark.svg');
	border-color: #0063f7;
}

.disabled .input {
	cursor: not-allowed;
	opacity: 0.5;
}

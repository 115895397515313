.toast-container {
	position: fixed;
	width: auto;
	height: auto;
	top: 65px;
	right: 10px;
	z-index: 20001; /* one above modal */
}

.toast-container {
	& > div {
		margin-bottom: 8px;
	}
}

.toastAnimation {
	animation: drop 400ms, fadein 500ms;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes drop {
	from {
		transform: translateY(-100px);
	}
	to {
		transform: translateY(0px);
	}
}

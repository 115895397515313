.container {
	max-width: 600px;
	justify-self: center;
	align-self: center;
	background-color: #fff;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 24px;

	&--border {
		border-top: 8px solid #6e00db;
	}

	&--padding {
		padding: 32px;
	}
}

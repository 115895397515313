.container {
	display: flex;

	.orgContainer {
		display: flex;
		align-items: center;

		&:hover {
			opacity: 0.75;
		}

		.orgPic {
			width: 50px;
			height: 50px;
			position: relative;

			.proIcon {
				position: absolute;
				bottom: 0px;
				right: -5px;
			}
		}

		.name {
			margin-left: 10px;
			color: #ffffff;
		}
		.address {
			color: #ffffff;
			margin-top: 4px;
			display: flex;
			align-items: center;
			margin-left: 10px;
			opacity: 0.75;
			& > svg {
				margin-right: 5px;
			}
		}
	}

	&.dark {
		.name {
			color: #000000;
		}
		.address {
			color: #000000;
		}
	}
}

.container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	font-weight: 100;
	margin-top: 50px;
}

.container__card {
	background-color: #3e464f;
	min-width: 320px;
	width: 680px;
	padding: 15px;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container__card > div {
	font-size: 20px;
	line-height: 50px;
	text-align: center;
}

.container__card__links {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	width: 360px;
	margin-left: 30px;
	margin-top: 10px;
}

.link {
	display: flex;
	align-items: center;
}

.link > a {
	text-decoration: underline;
	font-weight: normal;
	font-size: 18px;
}

.link__img__container {
	width: 55px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.link__img__container > img {
	margin: auto auto;
	height: 100%;
	width: auto;
}

@media screen and (max-width: 680px) {
	.container__card {
		width: 360px;
	}

	.container__card > div {
		font-size: 16px;
		line-height: 30px;
	}
}

.container {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.file-container {
	background-color: #646a73;
	border-radius: 10px;
	padding: 8px;
	margin: 16px 0;

	&:hover {
		opacity: 0.75;
		cursor: pointer;
	}

	& > div {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='1' stroke-dasharray='6 6' stroke-dashoffset='43' stroke-linecap='square'/%3e%3c/svg%3e");
	}
}

.text {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 24px;

	.highlight {
		color: #83ccb0;
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	width: 100%;
}

.alert {
	margin-bottom: 10px;
}

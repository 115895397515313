.desktop {
	padding: 5px 13px;
	@media screen and (max-width: 800px) {
		display: none;
	}
}

.mobile {
	@media screen and (min-width: 800px) {
		display: none !important;
	}
}

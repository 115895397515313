.container {
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	& hr {
		width: 100%;
		border-color: #707070;
		margin-bottom: 0px;
	}

	&__message {
		display: flex;
		button {
			margin-left: 8px;
		}
		align-items: flex-end;
		justify-content: flex-end;
	}
}

.bulk {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 14px;

	& button {
		width: fit-content;
	}
}

.non-members-header {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: 5px;
}

.non-member-list {
	background-color: #313131;
	border-radius: 4px;
	border: 1px solid #f7f7f7;
	height: 150px;
	max-height: 150px;
	overflow-y: auto;
	padding: 6px 10px;

	& > li {
		display: grid;
		grid-template-columns: 50px 1fr;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding: 4px 0px;
		overflow-x: hidden;
	}

	& > * {
		white-space: nowrap;
		overflow-x: auto;
	}
}

.dropdown {
	background-color: #171717;
	cursor: pointer;
	max-width: 200px;
	padding: 10px 10px 10px 15px;
	border: 1px solid #575757;
	border-radius: 4px;

	& svg {
		fill: #fff;
	}

	&--opened {
		background-color: #171717;
		max-height: 150px;
		overflow-x: hidden;
		overflow-y: auto;
		width: fit-content;
	}
}

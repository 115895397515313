.container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.card {
	display: flex;
	justify-content: center;
	font-size: 18px;
	max-width: 480px;
	color: #333;

	& h2 {
		text-align: center;
		font-size: 32px;
	}
}

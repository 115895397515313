.cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	& a {
		color: inherit;
	}
}

.cards > div {
	margin: 10px;
	min-width: 300px;
	background-color: #373737;
	padding: 20px;
	border-radius: 10px;
}

.cards > div > * {
	line-height: 2;
	font-size: 16px;
}

.versionContainer {
	position: absolute;
	bottom: 5px;
	right: 10px;
	opacity: 0.4;
}

.translationsContainer {
	margin-left: 20px;
	margin-top: 60px;
	@media (max-width: 1400px) {
		margin: 0;
		margin-top: 30px;
	}

	.header {
		padding: 0 20px;
		font-weight: 600;
		padding: 13px 0 13px 22px;
		color: #fff;
		background-color: #373737;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
	}
	.body {
		border-bottom-left-radius: 7px;
		border-bottom-right-radius: 7px;
		min-width: 600px;
		background: #474747;

		.singleRow {
			display: flex;
			align-items: center;
			border-top: 0.25px solid #707070;
			padding: 14px 20px;

			.changeCount {
				display: flex;
				flex-direction: row;
				font-size: 18px;
				color: #fff;
				font-weight: 500;

				span {
					margin: 0 6px;
				}
			}

			.singleChanges {
				line-height: 13px;
				color: #fff;
				display: flex;
				flex-direction: column;
				position: relative;
				width: 100%;

				.textFrom {
					opacity: 0.75;
					font-size: 11px;
				}
				.textTo {
					font-size: 16px;
				}
			}

			.singleRemove {
				&:hover {
					opacity: 0.75;
					cursor: pointer;
				}
				svg {
					width: 24px;
					height: 24px;
					fill: #fff;
				}
			}
		}

		.singleRowNoChanges {
			color: #fff;
			display: flex;
			justify-content: center;
			font-size: 12px;
			opacity: 0.6;
		}

		.singleRowButton {
			display: flex;
			justify-content: flex-end;

			& > div:not(.submittedTranslations) {
				width: 210px;
			}
		}

		.submittedTranslations {
			display: flex;
			align-items: center;
			color: rgb(6, 192, 6);
			font-weight: 500;
			font-size: 18px;
			white-space: nowrap;
			svg {
				margin-right: 5px;
				fill: rgb(6, 192, 6);
			}
		}

		.submittedTranslationsError {
			color: #ff5370;

			svg {
				width: 24px;
				height: 24px;
				fill: #ff5370;
			}
		}
	}
}

.container {
	.logoContainer {
		display: flex;
		padding: 25px 15px;

		img,
		svg {
			width: 64px;
			height: 64px;
		}

		.orgDetails {
			margin-left: 12px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.orgName {
				font-size: 17px;
				margin-bottom: 2px;
			}
			.id {
				font-size: 15px;
			}
		}
	}

	.errorMessages {
		margin-bottom: 20px;
	}

	.containerPrice {
		.current {
			opacity: 0.5;
		}
	}

	.numberOfLicensesContainer {
		display: flex;
		justify-content: space-between;
		position: relative;

		.addMoreBtn {
			right: 0;
			top: -20px;
			position: absolute;
		}
	}

	.inputsContainer {
		margin-top: 10px;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		position: relative;

		.numberInput {
			width: 150px;
			margin-left: 10px;
		}
	}

	.controls {
		margin-top: 10px;

		& > :first-child {
			margin-right: 10px;
		}
		display: flex;
		justify-content: right;
	}
}

.container {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	.subscriptionContainer {
		padding: 1rem;
		border-radius: 5px;

		.transactionContainer {
			margin-top: 1rem;

			.section {
				margin-bottom: 1rem;

				.sectionTitle {
					font-size: 1.2rem;
					font-weight: bold;
					margin-bottom: 0.5rem;
				}

				& > div {
					display: flex;
					justify-content: space-between;
					padding: 0.5rem 0;
					border-bottom: 1px solid #f0f0f0;

					&:last-child {
						border-bottom: none;
					}

					span:first-child {
						font-weight: bold;
					}
				}
			}
		}
	}
}

.alert {
	width: 100%;
	padding: 10px 14px;
	font-size: 15px;
	border-radius: 3px;
	border: solid 1px transparent;
	font-weight: 400;
	display: flex;
	align-items: center;

	svg {
		margin-right: 14px;
		fill: currentColor;
		width: 24px;
	}
}

.success {
	border-color: #2ed8b6;
	color: #2ed8b6;
}

.info {
	border-color: #00bcd4;
	color: #00bcd4;
}

.warning {
	border-color: #ffb64d;
	color: #ffb64d;
}

.primary {
	border-color: #4099ff;
	color: #4099ff;
}

.danger {
	border-color: #cd2626;
	color: #e7e7e7;

	svg {
		fill: #cd2626;
	}
}

.container {
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	color: white;

	> .section {
		display: flex;
		flex-direction: column;
		max-width: 600px;
		width: 100%;
	}
}

.container > h1 {
	font-weight: bold;
	font-size: 30px;
	text-align: center;
	margin: 10px 0px 0px 0px;
}

.container > div {
	padding: 20px;
}

.block {
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: column;
	margin: 25px 0px;
}

.versionHeadContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 0px 0px 20px 0px;

	h4 {
		font-weight: bold;
		text-align: center;
		font-size: 24px;
		margin: 0;
	}
}

.versionDate {
	font-size: 0.8rem;
}

.block > h4 {
	font-weight: bold;
	text-align: center;
	font-size: 24px;
	margin: 0px 0px 25px 0px;
}

.entry {
	display: flex;
	height: fit-content;
	margin: 0px 0px 10px 20px;
}

.block label.versionTag {
	display: inline-table;
	text-align: center;
	padding: 1px 4px;
	border-radius: 4px;
	font-size: 0.7rem;
	letter-spacing: 0.1px;
	text-transform: uppercase;
	margin-bottom: 5px;
}

.entry > div {
	margin-left: 5px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.select {
	border: none;
	padding: 0.25em 1.5em 0.25em 0.5em;
	position: relative;

	z-index: 1;

	border: 1px solid #fff;
	border-radius: 0.25em;

	font-size: 1.25rem;
	cursor: pointer;
	line-height: 1.1;

	background-color: #000000;
	background-image: linear-gradient(to top, #000, #000 33%);
	color: white;
}

@media screen and (max-width: 700px) {
	.entry {
		flex-direction: column;
	}

	.entry > div {
		margin-left: 0px;
	}

	.block > h4 {
		text-align: center;
		font-size: 17px;
		margin-bottom: 15px;
	}
}

.container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	color: black;
	margin-top: 10px;
}

.contentContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	border-top: 11px solid #0063f7;
	padding: 40px;

	svg {
		height: 150px;
		width: 150px;
	}

	.buttonsContainer {
		margin-top: 30px;
	}

	.authenticatedBtn {
		margin-top: 10px;
	}
}

.container h3 {
	margin-top: 30px;
	font-size: 25px;
}

.container > div {
	background-color: white;
	border-radius: 8px;
	width: 100%;
	max-width: 420px;

	.buttonsFooter {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
	}

	button {
		width: 100%;
	}
}

.heading {
	font-size: 35px;
	text-align: center;
	margin: 50px;
}

.content {
	display: flex;
	flex: 1;
	flex-direction: column;
	max-width: 1200px;
	width: 100%;
	margin: auto auto;
	gap: 14px;
	padding: 14px;

	& > button {
		max-width: fit-content;
	}
}

.legacyDownloadsRowAuto {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-top: 30px;

	.ctaButton {
		// on hover
		&:hover {
			background-color: #aaaaaa;
		}
	}

	.tabs {
		display: flex;
		justify-content: center;
		align-items: stretch;
		position: relative;
		width: 100%;
		margin-bottom: 30px;

		.tab {
			font-size: 1.2rem;
			line-height: 1;
			text-align: center;
			font-weight: 700;
			margin: 0;
			border-bottom: 4px solid #858b8f;
			padding-bottom: 8px;
			padding-left: 20px;
			padding-right: 20px;

			@media (max-width: 768px) {
				width: 100%;
				font-size: 0.8rem;
				padding-left: 0px;
				padding-right: 0px;
				min-width: none;
			}
		}

		.tab.active {
			color: var(--sterry-primary-color);
			border-bottom: 4px solid #ffffff;

			@media (max-width: 768px) {
				min-width: none;
			}
		}

		.tab:hover {
			cursor: pointer;
		}

		.tab:not(.active):hover {
			color: var(--sterry-primary-color);
		}
	}

	.tabContent {
		display: flex;
		flex-direction: column;
		width: 100%;
		background-color: #ffffff;
		border-radius: 10px;

		.tabContentItem {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex: 1;
			width: 100%;
			color: #575757;
			border-bottom: 1px solid #e5e5e5;

			&:last-of-type {
				border-bottom: none;
			}
		}

		.tabContentItemIcon {
			width: 54px;
			height: 54px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-left: 20px;

			& > img {
				width: 44px;
				height: 44px;
			}

			& > svg {
				width: 44px;
				height: 44px;
			}

			@media (max-width: 768px) {
				width: 44px;
				height: 44px;
				padding-left: 10px;

				& > img {
					width: 34px;
					height: 34px;
				}
			}
		}

		.tabContentItemDetails {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 5px;
			padding-left: 20px;
			padding-right: 20px;
			width: 100%;

			@media (max-width: 768px) {
				padding-left: 10px;
				padding-right: 10px;
				width: 100%;
			}
		}

		.tabContentItemName {
			line-height: 1;
			color: #636363;
			text-align: left;
			margin: 0;
			flex: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.tabContentItemVersion {
			line-height: 1;
			text-align: left;
			margin: 0;
			min-width: 150px;
		}

		.tabContentItemDownload {
			line-height: 1;
			text-align: left;
			font-weight: 700;
			margin: 0;
			padding-left: 20px;
			padding-right: 10px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			flex-wrap: nowrap;
			gap: 10px;
		}

		.tabContentItemDownloadGrayButton {
			display: flex;
			background-color: #e7e7e7;
			color: #000000;
			border-radius: 10px;
			padding: 10px 10px;
			line-height: 1;
			text-align: center;
			white-space: nowrap;
			cursor: pointer;

			@media (max-width: 768px) {
				font-size: 0.8rem;
			}
		}
	}
}

.container {
	display: flex;
	max-width: 1280px;
	width: 100%;
	flex-direction: column;
	padding: 20px;

	.sessionGroupContainer {
		display: flex;
		border: 1px solid var(--sterry-color-grey);
		background: var(--sterry-modal-background-color);
		border-radius: var(--sterry-modal-border-radius);
		padding: 20px;
		padding-bottom: 0px;
		margin-bottom: 20px;

		.groupHeader {
			display: flex;
			flex-direction: column;
			align-items: center;
			min-width: 250px;
			font-size: 18px;
			margin-right: 20px;
			margin-bottom: 20px;
			padding-right: 40px;
			border-right: 1px solid var(--sterry-color-grey);

			svg {
				width: 84px;
				fill: var(--sterry-color-grey);
				height: 84px;
				margin-bottom: 10px;
			}
		}

		.sessionList {
			display: flex;
			flex-direction: column;
			flex: 1;

			.singleSession {
				position: relative;
				border-bottom: 1px solid var(--sterry-color-grey);
				flex: 1;
				display: flex;
				flex-direction: column;
				margin-bottom: 20px;
				margin-left: 20px;
				padding-right: 20px;
				padding-bottom: 20px;

				.signedOutContainer {
					display: flex;
					position: absolute;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					opacity: 0.9;
					font-size: 16px;
				}

				&:last-child {
					border-bottom: none;
					margin-bottom: 0px;
				}

				.lastUpdatedContainer {
					margin-top: 4px;
				}

				.firstRow {
					flex-wrap: wrap;
					display: flex;
					justify-content: space-between;

					.browserContainer {
						display: flex;
						align-items: center;

						.browserIcon {
							fill: #fff;
							width: 24px;
							height: 24px;
							margin-right: 10px;
						}
					}

					.locationContainer {
						display: flex;
						flex-direction: row;
						align-items: center;
						margin-bottom: 2px;

						.locationIcon {
							fill: #fff;
							margin-right: 10px;
							width: 24px;
							height: 24px;
						}
					}
				}

				.bottomContainer {
					margin-top: 60px;
					display: flex;
					justify-content: space-between;

					.currentSessionContainer {
						display: flex;
						align-items: center;

						.currentSessionIcon {
							width: 24px;
							height: 24px;
							margin-right: 10px;
							fill: #06c270;
						}
					}

					.firstSignInContainer {
						position: relative;

						.title {
							margin-right: 6px;
							opacity: 0.7;
						}

						.titleContainer {
							width: fit-content;
							white-space: nowrap;
							display: flex;
							position: absolute;
							bottom: 0;
						}
					}
				}
			}

			.signedOutSingleSession {
				.bottomContainer,
				.firstRow,
				.bottomContainer,
				.locationContainer,
				.lastUpdatedContainer {
					pointer-events: none;
					opacity: 0.5;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.container {
		.sessionGroupContainer {
			flex-direction: column;
			margin-right: 0;

			.groupHeader {
				border-right: none;
				border-bottom: 1px solid var(--sterry-color-grey);
				padding-bottom: 20px;
				padding-right: 0;
				margin-right: 0;
			}
			.sessionList {
				.singleSession {
					margin-right: 20px;
					padding-right: 0;

					.bottomContainer {
						flex-wrap: wrap;

						.firstSignInContainer {
							align-items: center;
							display: flex;

							.titleContainer {
								position: unset;
							}
						}
					}
				}
			}
		}
	}
}

.container {
	max-height: 540px;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 10px;

	& button {
		width: 100%;
	}
}

.modal {
	height: 600px;
}

.buttons {
	display: flex;
	column-gap: 14px;
}

.container {
	display: grid;
	grid-template-columns: 20px 40px 3fr 1fr 1fr 40px;
	align-items: center;
	justify-content: flex-start;
	column-gap: 10px;
	width: 100%;
	font: normal normal 300 14px/16px Poppins;
	color: #272727;
	overflow: auto;

	& > div {
		white-space: nowrap;
	}

	&__icon {
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		justify-self: center;
	}

	&__users {
		display: flex;
		column-gap: 15px;
		align-items: center;
		justify-self: flex-end;

		& > svg {
			fill: #a7a7a7;
			width: 40px;
			height: 40px;
			object-fit: contain;
		}
	}

	&__ellipses {
		fill: #a7a7a7;
		width: 30px;
		height: 30px;
		object-fit: contain;
		cursor: pointer;

		&:hover {
			fill: #e7e7e7;
		}
	}

	&__open {
		fill: #a7a7a7;
		width: 30px;
		height: 30px;
		object-fit: contain;
		cursor: pointer;
		justify-self: flex-end;

		&:hover {
			fill: #e7e7e7;
		}
	}
}

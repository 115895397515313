.toggle {
	width: 52px;
	height: 28px;
	border-radius: 16px;
	background-color: #e2e2e2;
	display: table;
	position: relative;
	cursor: pointer;

	.loadingSpinnerContainer {
		position: absolute;
		background: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		border-radius: 16px;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		& > div {
			width: 20px;
			height: 20px;
		}
	}

	input[type='checkbox'] {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.toggle-slider {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		border-radius: 16px;
		background-color: #d5d5d5;
		transition: all 0.3s ease-in-out;
		border: 1px solid transparent;

		&:before {
			position: absolute;
			content: '';
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 1px solid #a8a8a8;
			background-color: #ffffff;
			transition: all 0.3s ease-in-out;
		}
	}

	input[type='checkbox']:checked + .toggle-slider {
		background-color: #0063f7;

		&:before {
			transform: translateX(26px);
			border-color: #0063f7;
		}
	}
}

.container {
	position: relative;
	border-radius: 50%;
	min-width: 200px;
	min-height: 200px;
	width: 200px;
	height: 200px;
	border: 1px solid #d7d7d7;

	&:hover {
		opacity: 0.75;
		cursor: pointer;
	}

	& > img {
		border-radius: 50%;
		width: 100%;
		height: 100%;
	}

	&::after {
		content: '';
		border-radius: 50%;
		position: absolute;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		background-image: linear-gradient(180deg, transparent 70%, rgba(39, 39, 39, 0.5) 70.5%);
	}

	.photoCameraIcon {
		width: 28px;
		position: absolute;
		bottom: 20px;
		left: calc(50% - 14px);
		fill: #fff;
		z-index: 1;
	}
}

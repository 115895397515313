.container {
	display: flex;
	justify-content: center;
	width: 100%;
	height: calc(100vh - var(--sterry-header-height));
	max-height: 1080px;
	overflow: hidden;

	& img {
		object-fit: contain;
	}

	&__smooth-image {
		& img {
			border-radius: 6px;
			background-color: rgba(255, 255, 255, 0.2);
			padding: 12px;
		}
	}

	&__grid {
		width: 90%;
		max-width: 1920px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 40px;
		padding: 0px 100px;
		justify-items: center;
		justify-content: center;

		& > div {
			width: 49%;
			background-color: transparent;
		}

		& > img {
			max-width: 49%;
			max-height: 65%;
		}

		& > svg {
			height: auto;
			max-width: 49%;
			max-height: 65%;
		}
	}

	&--auto-height {
		height: auto;
		padding: 20px 0px;
	}

	@media screen and (max-width: 576px) {
		.container {
			&__grid {
				padding: 0px;
				width: 100%;
			}

			&--inverted {
				flex-direction: column-reverse;
			}
		}
	}
}

@media screen and (max-width: 1600px) {
	.container {
		&__grid {
			gap: 20px;
		}
	}
}

@media screen and (max-width: 576px) {
	.container {
		display: flex;
		padding-bottom: 20px;
		flex-direction: row-reverse;

		&__grid {
			display: flex;
			flex-direction: column;

			& > div {
				width: 100%;
				padding: 0 20px;
			}
		}
	}
}

@media screen and (max-width: 1366px) {
	.container {
		height: auto;
		background-color: rgb(39, 39, 39);
		align-items: center;

		&__grid {
			height: 75%;
			display: flex;
			justify-content: space-between;
			padding: 20px 20px;

			& > div {
				margin-top: 20px;
				margin-bottom: 20px;
				flex: 1;
				justify-content: center;
			}

			& > img {
				max-width: 60%;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.container {
		&__grid {
			& > div a {
				margin: 5px 0 5px 0;
			}
		}
	}
}
